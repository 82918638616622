import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './Auth.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { apilink, path } from '../../data/fdata';
import { useAlert } from 'react-alert';

const ForgetPassword = () => {
    const alert = useAlert();
  const [email, setEmail] = useState('');
  
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const his = useHistory();
  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res=await axios.post(`${apilink}/api/admin/forgetPassword`,{
      email
    })
    // console.log(res.data)
    if(res.data.success)
    {
      
     
     setTimeout(() => {
      alert.success(res.data.msg)
      setLoading(false);
     }, 2000);

     setTimeout(() => {
      window.location.href = '/';
     }, 4000);

    }else{
      setTimeout(() => {
        setMsg(res.data.msg);
      setStatus(true);
      setLoading(false);
      }, 2000);
    }

    
  };

  return (
    <div className="auth">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-7 col-12 mx-auto">
          <div className="card p-3">
            {status ? (
              <>
                <div class="alert alert-warning alert-dismissible">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    onClick={() => setStatus(false)}
                  >
                    &times;
                  </button>
                  {msg}
                </div>
              </>
            ) : null}
        <img
                src={`${path}/image/Indiayour.png`}
                alt=""
                className="logoimg"
              />
            <h3 className="text-center pb-3">Forget Password</h3>
            <br />
            <form onSubmit={onSub} className="">
              <div class="form-group">
                <input
                  type="email"
                  placeholder="Enter Email to Get Reset Password Link"
                  class="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
             
<p className='fn_12 mt15 cur' onClick={()=>his.push("/")}>Back to Login</p>
             
              <div className="text-center">
                <button
                  type="submit"
                  className={
                    loading ? 'dis btn btn-primary' : 'btn btn-primary'
                  }
                  disabled={loading}
                >
                  Send Now
                </button>
              </div>
              {loading && (
                <div className="text-center p-2">
                  <CircularProgress color="error" size={35} />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ForgetPassword