// export const apilink = 'http://localhost:5000';
// export const path = 'http://localhost:4502';
// export const mainpath="http://localhost:4551"



export const mainpath="https://indiayour.com"
export const apilink = 'https://api.indiayour.com'
export const path = 'https://admin.indiayour.com';



export const CategoryList = [
    {
      title: 'Small Business Machinary',
        orders:150
    },
  
   {
  
      title: 'Office Stationery & Supplies',
      orders:120
    },
  {
  
      title: 'Healthcare Supplies',  
      orders:100
    },
  {
  
      title: 'Cleaning & Housekeeping',
      orders:50
    },
  
  {
    title:'Agriculture & Landscaping Supplies',
    orders:500
  },
  {
    title:"Bulding & Construction Supplies",
    orders:70
  }
  ,{
    title:'Industrial Supplies',
    orders:15
  },
  {
    title:'Safety Equipment Supplies',
    orders:60
  }
  ]

  export const headerNav = [
    {
      display: 'Dashboard',
      path: '/home',
    },
    {
      display: 'Products',
      path: '/products',
    },
    
    {
      display: 'Order Requests',
      path: '/order-requests',
    },
    {
      display:'Seller Details',
      path: '/seller',
    },{
      display:'User Details',
      path: '/user',
    },
    {
      display: 'Product Sell Details',
      path:'/product-sell-details'
    },
    {
      display: 'Category',
      path:'/category'
    },
    {
      display: 'Banner',
      path:'/banner'
    },
    {
      display: 'Ads',
      path:'/ads'
    },
    {
      display:"About Us",
      path:"/about-us"

    },
    {
      display:"Contact Us",
      path:"/contact-us"

    },
    {
      display:"Query",
      path:"/query"

    },
    {
      display:"Privacy Policy",
      path:"/privacy-policy"

    },
    {
      display:"Terms & Conditions",
      path:"/terms-conditions"

    },
    {
      display:"Sitemap Settings",
      path:"/sitemap-settings"

    }
  ];

  export const  removeSpecialCharacters=(text) =>{
    // Define a regular expression pattern to match special characters
    const regexPattern = /[&\/\\#,+()$~%.'":*?<>{}]/g;
  
    // Use the replace method to remove the special characters
    let cleanedText = text?.replace(regexPattern, '');
    cleanedText = cleanedText?.replace(/\s+/g, ' ');
  // console.log(cleanedText)
    return cleanedText;
  }

  export const discountPriceFunc=(price,disc)=>{
    let discount = (disc * price) / 100;
    let  discountedAmount=(Math.round(price-discount))
    return discountedAmount;
  }
export const gstPriceFunc=(price, discount , gst)=>{
  let total= discountPriceFunc(price,discount)
  
 let GSTamount= Math.round((total*gst)/100)
//  console.log(total , GSTamount)
  return total+GSTamount;

}