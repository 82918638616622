import React from "react";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { apilink } from "../../data/fdata";
import axios from "axios";
import { useAlert } from 'react-alert';


const SellerList = ({token,sellerList}) => {
  // console.log(sellerList)
  const alert = useAlert();
  const [filter, setFilter] = useState("All");
  const [showValue, setShowValue] = useState("");

  const [status,setStatus]=  useState('')
  const [activeId,setActiveId]=  useState('')
  const [loading, setLoading] = useState(false);
  const [myData, setMyData] = useState(sellerList);
  const [pageNo, setPageNo] = useState(0);
  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };
  const updateSellerStatus =async (e) => {
    e.preventDefault()
    setLoading(true)
    let st
    if(status=="Pending")
    {
      st=false
    }else{
      st=true
    }
    const res=await axios.post(`${apilink}/api/seller/updateSellerStatus`,{
      activeid:activeId,status:st
    },{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)
    if(res.data.success)
    {
      const updatedItems = myData.map((item) =>
        item._id == activeId ? {...item, ['isApproved']: st } : item
      );
      
      
      setMyData(updatedItems);
      setTimeout(() => {
        setStatus("")
      // setActiveId("")
        setLoading(false)
        alert.success(res.data.msg)
      }, 2000);
    }else{
      setTimeout(() => {
        setLoading(false)
        alert.error(res.data.msg)
        
      }, 2000);
    }
  };


  const handelItem=(e)=>{
    setFilter(e.target.value)
    let f= e.target.value=="pending" ?  false :true
    if(e.target.value=="All")
    {
      
      setMyData(sellerList)
      
    }else{
      setMyData(sellerList.filter((v)=>v.isActive == f))
    }
  }
  return (
    <>
      <div className="card p-3">
        <div className="dd_flex">
          <h5 className="text_Deco">All Sellers</h5>
          <div>
            <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={handelItem}>
               
                <option value="All">All</option>
                <option value="approved">Approved</option>
               
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>
        </div>
        <div class="table-responsive ">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th className="w_d">Date</th>
                <th className="tr_w">Business Name</th>
                <th className="w_30">Email</th>
                <th className="w_20">Phone</th>
                <th className="w_20">Operation</th>
                <th className="w_20">Status</th>
              </tr>
            </thead>
            <tbody>
              {dataall.map((val, ind) => {
                return (
                  <>
                    <tr className="">
                    <td className="w_d">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>

                      <td className="tr_w">
                        <NavLink to={`/seller/${val._id}/products`} className="text-custom">
                         {val.comp_name}
                        </NavLink>
                      </td>
                      <td className="w_30">{val.email}</td>
                      <td className="w_20"><a href={`tel:${val.phone}`}>{val.phone}</a></td>
                      <td className="w_20">
                        <span
                          class="badge badge-dark m-1 cur"
                          data-toggle="modal"
                          data-target={`#myyModal${val._id}`}
                          
                        >
                          View
                        </span>

                        <div class="modal fade" id={`myyModal${val._id}`}>
                          <div class="modal-dialog modal-lg modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Seller Business Details</h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>

                              <div class="modal-body">
                              <div className="row mt-4 ">
                <div className="col-12">
                 
                    <h3 className="text_Deco mb-3">Business Details</h3>
                    {val?.businessDetails?.address ? (
                      <>
                        <h5>Business Address: {val?.businessDetails?.address}, {val?.businessDetails?.city}, {val?.businessDetails?.pincode}, {val?.businessDetails?.state}</h5>
                        <h5> Business Type: {val?.businessDetails?.businessType}</h5>
                        <h5> Industry or Category:{val?.businessDetails?.category}</h5>
                        <h5> Seller Description:{val?.businessDetails?.sellerDet}</h5>
                        <h5> Team Size:{val?.businessDetails?.teamsize}</h5>
                        <h5> GSTIN : {val?.businessDetails?.gstinNo}  </h5>
                        <img src={val?.businessDetails?.gstinFile} alt="" className="doc_fileimg" />
                        <h5> PAN:{val?.businessDetails?.panNo} </h5>
                        <img src={val?.businessDetails?.panFile} alt="" className="doc_fileimg" />

                        
                      </>
                    ) : (
                      <>
                        <div className="text-left">
                          <button  className="btn btn-primary">Not</button>
                        </div>
                      </>
                    )}
                  
                </div>
              </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <span
                          class="badge badge-primary m-1 cur"
                          data-toggle="modal"
                          data-target={`#myyyModal${val._id}`}
                          onClick={()=>setActiveId(val._id)}
                        >
                          Update
                        </span>

                        <div class="modal fade" id={`myyyModal${val._id}`}>
                          <div class="modal-dialog modal modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">
                                  Approve Seller Business Details
                                </h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>

                              <div class="modal-body">
                                <div className="row">
                                  <div className="col-lg-8 col-12 mx-auto">
                                    <form onSubmit={updateSellerStatus}>
                                      <div class="form-group">
                                        <select class="form-control " id="sel1" value={status} onChange={(e)=>setStatus(e.target.value)} required>
                                        <option selected hidden value="">--Choose--</option>

                                          <option value="Approved">
                                            Approved
                                          </option>
                                         
                                          <option value="Pending">
                                            Pending
                                          </option>
                                        </select>
                                      </div>
                                      <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                     Update Status
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="w_20">
                        {
                         val.isActive  ? <span class="badge badge-success m-1">Approved</span> :  <span class="badge badge-warning m-1">Pending</span>
                        }
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        

        {
          dataall.length > 0 && <div className="row mt-3">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            pageCount={boxno}
            onPageChange={likedChange}
            containerClassName={"pagination"}
            // previousLinkClassName={"prevbutton"}
            // nextLinkClassName={"nextbutton"}
            // disabledClassName={"pagedisable"}
            activeClassName={"activebutton"}
          />
        </div>
        }
      </div>
    </>
  );
};

export default SellerList;
