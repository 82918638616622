import './Need.css'

import React, { useEffect, useState } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useAlert } from 'react-alert';


const ContactUs = () => {
    const [pageloading, setPageLoading] = useState(false);
    const alert = useAlert();

    const token = Cookies.get('_indiayour_admin_access_token');
    const his = useHistory();
    const [userData, setUserData] = useState([]);

    const [cID, setCID] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    const [faceLink, setFaceLink] = useState('');
    const [instLink, setInstLink] = useState('');
    const [lindLink, setLindLink] = useState('');
    const [twiLink, setTwiLink] = useState('');




    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              if(!res.data.success)
              {
                Cookies.remove('_indiayour_admin_access_token');
               localStorage.removeItem('_indiayour_admin_access_login');
              console.clear();
              window.location.href = '/';
              }else{
                
                setUserData(res.data.userInfo)
    
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 2000);
             
        }else{
          his.push("/")
        }
      }, []);

      const onSubmitContact=async(e)=>{
        e.preventDefault()
        setPageLoading(true)
        if(cID)
        {
          const data={
            cID,
            email,
            phone,
            address,
            facebook:faceLink,
            linkedin:lindLink,
            instagram:instLink,
            twitter:twiLink
          }
  
          const res=await axios.post(`${apilink}/api/contactlink/updateContactLink`,data,{
            headers: {
                
              Authorization: token,
            },
          })
          console.log(res.data)
          if(res.data.success)
          {
            setTimeout(() => {
              setPageLoading(false)
              alert.success(res.data.msg)
            }, 2000);
  
          }else{
            setTimeout(() => {
              setPageLoading(false)
              alert.error(res.data.msg)
            }, 2000);
  
          }
  
        
  

        }else{

        
        const data={
         
          email,
            phone,
            address,
            facebook:faceLink,
            linkedin:lindLink,
            instagram:instLink,
            twitter:twiLink
        }

        const res=await axios.post(`${apilink}/api/contactlink/postContactLink`,data,{
          headers: {
              
            Authorization: token,
          },
        })
        if(res.data.success)
        {
          setTimeout(() => {
            setPageLoading(false)
            alert.success(res.data.msg)
          }, 2000);

        }else{
          setTimeout(() => {
            setPageLoading(false)
            alert.error(res.data.msg)
          }, 2000);

        }

      }


        
      }
      
      const getAllSocial=async()=>{
        const res=await axios.get(`${apilink}/api/contactlink/getContactLinks`)
        if(res.data.success)
        {
          setCID(res.data.result._id)
          setEmail(res.data.result.email)
          setPhone(res.data.result.phone)
          setAddress(res.data.result.address)
          setFaceLink(res.data.result.facebook)
          setLindLink(res.data.result.linkedin)
          setInstLink(res.data.result.instagram)
          setTwiLink(res.data.result.twitter)
        }
      }
      useEffect(()=>{
        getAllSocial()

      },[])

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12 mb-3 mx-auto">
                <div className="card p-3">
                <h3 className='text-center text-custom'>Contact Us</h3>
                  <form onSubmit={onSubmitContact}>
                  <div className="form-group ">
                       <label for="address">Email:</label>
                       <input
                         type="email"
                         placeholder="Enter Email"
                         className="form-control form_h"
                         name="email"
                         value={email}
                         onChange={(e) => setEmail(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label for="address">Phone:</label>
                       <input
                         type="text"
                         placeholder="Enter Phone"
                         className="form-control form_h"
                         name="phone"
                         value={phone}
                         onChange={(e) => setPhone(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label for="address">Address:</label>
                       <input
                         type="text"
                         placeholder="Enter Address"
                         className="form-control form_h"
                         name="address"
                         value={address}
                         onChange={(e) => setAddress(e.target.value)}
                         required
                       />
                     </div>
                     <div className="text-center ">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      
                    >
                      Update Now
                    </button>
                  </div>
                  </form>
                </div>
               
              </div>
              <div className="col-lg-6 col-md-12 col-12 mb-3 mx-auto">
                <div className="card p-3">
                  <h3 className='text-center text-custom'>Social Links</h3>
                  <form onSubmit={onSubmitContact}>
                  <div className="form-group ">
                       <label for="">Facebook Link:</label>
                       <input
                         type="text"
                         placeholder="Facebook Link"
                         className="form-control form_h"
                         name="faceLink"
                         value={faceLink}
                         onChange={(e) => setFaceLink(e.target.value)}
                         
                       />
                     </div>

                     <div className="form-group ">
                       <label for="">Linkedin Link:</label>
                       <input
                         type="text"
                         placeholder="Linkedin Link"
                         className="form-control form_h"
                         name="lindLink"
                         value={lindLink}
                         onChange={(e) => setLindLink(e.target.value)}
                         
                       />
                     </div>

                     <div className="form-group ">
                       <label for="">Instagram Link:</label>
                       <input
                         type="text"
                         placeholder="Instagram Link"
                         className="form-control form_h"
                         name="instLink"
                         value={instLink}
                         onChange={(e) => setInstLink(e.target.value)}
                         
                       />
                     </div>
                     <div className="form-group ">
                       <label for="">Twitter Link:</label>
                       <input
                         type="text"
                         placeholder="Twitter Link"
                         className="form-control form_h"
                         name="twiLink"
                         value={twiLink}
                         onChange={(e) => setTwiLink(e.target.value)}
                         
                       />
                     </div>

                     <div className="text-center">
                     <button
                      type="submit"
                      className="btn btn-primary"
                      
                    >
                      Update Now
                    </button>
                     </div>
                  </form>
                </div>
               
              </div>
            </div>
           

          </div>
        </div>
      </div>
      

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    
    </>
  )
}

export default ContactUs