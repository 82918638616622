import React from "react";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { apilink } from "../../data/fdata";
import axios from "axios";

const UserList = ({getallusers, setPageLoading,token, userList , setUserList}) => {
 

  const [myData, setMyData] = useState(userList);
  const [pageNo, setPageNo] = useState(0);
  const perpage = 15;
  const pagevisit = pageNo * perpage;
  const alert = useAlert();
  const [filter, setFilter] = useState("All");

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);
  const his = useHistory();

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };

  const updateSeenUser=async(id)=>{
    setPageLoading(true)
    const res=await axios.post(`${apilink}/api/user/updateSeenUser`,{
      uid:id
    },{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)
    if(res.data.success) 
    {
     let ar= userList.map((v)=>{
        if(v._id==id)
        {
          v.isActive= !v.isActive
        }
        return v;
      })
      // console.log(ar)
      setUserList(ar)
      setTimeout(() => {
        alert.success(res.data.msg)
        setPageLoading(false)
      }, 2000);
      
    }else{
      setTimeout(() => {
        alert.error(res.data.msg)
        setPageLoading(false)
      }, 2000);
    }

  }


const onDeleteUser=async(id)=>{
  setPageLoading(true)
  const res=await axios.post(`${apilink}/api/user/onDeleteUser`,{
    uid:id
  },{
    headers: {
      Authorization: token,
    },
  })
  // console.log(res.data)
  if(res.data.success) 
  {
   let ar= userList.filter((v)=>v._id != id)
    // console.log(ar)
    getallusers()
    setMyData(ar)
    
    setTimeout(() => {
      alert.success(res.data.msg)
      setPageLoading(false)
    }, 2000);
    
  }else{
    setTimeout(() => {
      alert.error(res.data.msg)
      setPageLoading(false)
    }, 2000);
  }
}

const handelProduct=(e)=>{
  setFilter(e.target.value)
  let f= e.target.value=="pending" ?  false :true
  if(e.target.value=="All")
  {
    
    setMyData(userList)
    
  }else{
    setMyData(userList.filter((v)=>v.isActive == f))
  }
  
}

  return (
    <>
    <div className="card p-3">
        <div className="dd_flex">
          <h5 className="text_Deco">All Users</h5>
          <div>
              <div class="form-group">
                 <select class="form-control filterform" value={filter} onChange={handelProduct}>
                  <option selected hidden value="">
                    --Filter--
                  </option>
                  <option value="All">All</option>
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>

                </select>
              </div>
            </div>
          
        </div>
        <div class="table-responsive ">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th className="w_d">Date</th>
                <th className="w_30">Name</th>
                <th className="w_30">Email</th>
                <th className="w_20">Phone</th>
                
                <th className="w_20">Orders</th>
                
                <th className="w_20">Operation</th>
              </tr>
            </thead>
            <tbody>
              {dataall.map((val, ind) => {
                return (
                  <>
                    <tr className="">
                    <td className="w_d">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>

                      <td className="w_30">
                       {val.name}
                      </td>
                      <td className="w_30">{val.email}</td>
                      <td className="w_20">{val.phone}</td>
                     
                      <td className="w_20">
                       <button className="btn btn-primary p-1 px-2" onClick={()=>his.push(`/user/${val._id}/orders`)}>View Orders</button>

                 
                        

                       
                      </td>
                      <td className="w_20">
                      {
                        val?.isActive ? <span className="cur badge badge-success" onClick={()=>updateSeenUser(val._id)}>Active</span> : <span className="cur badge badge-warning" onClick={()=>updateSeenUser(val._id)}>Pending</span>
                      }
                      &nbsp;      &nbsp; 
                      <span className="cur" onClick={()=>onDeleteUser(val._id)}><i className="fa fa-trash"></i></span>
                      </td>
                      
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
       {
        dataall.length > 0 &&  <div className="row mt-3">
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={boxno}
          onPageChange={likedChange}
          containerClassName={"pagination"}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={"activebutton"}
        />
      </div>
       }
      </div>
    
    
    </>
  )
}

export default UserList