import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import MyListC from '../../component/mylistcompo/MyListC';

const MyList = () => {
  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const token = Cookies.get('_indiayour_admin_access_token');


  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            
           

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              {/* <h3 className="text_Deco ml-2">All Products</h3> */}
              <div className="row mt-2 p-2">
                <div className="col-lg-12 col-md-12 mb-3">
                  <MyListC setPageLoading={setPageLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default MyList;
