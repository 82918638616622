import React, { useState } from 'react';

function DynamicInputFields({ subCatDetails, setSubCatDetails }) {

  // Function to add a new semester field
  const addSubCatDetail = () => {
    setSubCatDetails([...subCatDetails, { subcat_name: '' }]);
  };

  // Function to handle changes in semester field values
  const handleSubCatDetailChange = (index, event, field) => {
    const newSubCatDetails = [...subCatDetails];
    newSubCatDetails[index][field] = event.target.value;
    setSubCatDetails(newSubCatDetails);
  };

  const deleteSubCatDetail = (index) => {
    const newSubCatDetails = [...subCatDetails];
    newSubCatDetails.splice(index, 1);
    setSubCatDetails(newSubCatDetails);
  };

  // Function to render the semester fields
  const renderSubCatDetails = () => {
    return subCatDetails.map((subCatDetail, index) => (
      <div className="relative card p-2 mb-2" key={index}>
        <div className="form-group ">
            
            <input
              type="text"
              className="form-control form_h"
              placeholder="Ex: Subcategory Name"
              value={subCatDetail.subcat_name}
              onChange={(event) => handleSubCatDetailChange(index, event, 'subcat_name')}
              required
            />
          </div>
        {
          subCatDetails.length > 1 && (
            <div className='delete_box'>
              <p className='m-0 cur' onClick={() => deleteSubCatDetail(index)}>
                <i className='fa fa-trash'></i>
              </p>
            </div>
          )
        }
      </div>
    ));
  };

  return (
    <div className='mb-4'>
      {renderSubCatDetails()}
      <span className='badge badge-dark cur' onClick={addSubCatDetail}>Add Subcategory</span>
    </div>
  );
}

export default DynamicInputFields;
