import React from 'react'
import CircularProgress from "@mui/material/CircularProgress";
import { apilink, mainpath, path, removeSpecialCharacters } from '../../data/fdata';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';


const SellerDetails = ({token , alert , sellerProductList}) => {
  const his = useHistory();

  const [pdata,setPData]=useState(sellerProductList)
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [activeId, setActiveId] = useState("");


  const onupdateProductStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios.post(
      `${apilink}/api/product/updateStatus`,
      {
        status,
        oid: activeId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    // console.log(res.data)

    if (res.data.success) {
      alert.success(res.data.msg);
      // console.log(activeId);
      const updatedItems = pdata.map((item) =>
        item.product._id == activeId ? {
      
        ...item,
        product: {
          ...item.product,
          isProApproved:status,
        },
      } : item
      );
      // console.log(updatedItems);
      setStatus("")
      setActiveId("")
      setPData(updatedItems);
    } else {
      if (res.data.msg == "Invalid Authentication.") {
        Cookies.remove("_indiayour_admin_access_token");
        localStorage.removeItem("_indiayour_admin_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        alert.error(res.data.msg);
      }
    }
    setLoading(false);
  };

  const deleteProduct=async(id)=>{
    
    const res=await axios.get(`${apilink}/api/product/deleteProductById/${id}`,{
      headers: {
        Authorization: token,
      },
    })
    if(res.data.success){
      alert.success(res.data.msg)
      setPData(sellerProductList.filter(pro =>pro.product._id!==id))

    }else{
      alert.error(res.data.msg)
    }

  }

  return (
    <>
    <div className="row">
                   {
                    pdata.map((val,ind)=>{
                      return(
                        <>
                         <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6  col-12  mb-3">
                      <div className="card p-2 sell_box">
                        <div className="status_card">
                          {
                            val.product.isProApproved=='pending' && <span class="badge badge-warning">Pending</span>
                          }
                          {
                            val.product.isProApproved=='rejected' && <span class="badge badge-danger">Rejected</span>
                          }

                    { 
                            val.product.isProApproved=='approved' && <span class="badge badge-success">Approved</span>
                          }

                        </div>
                        <div className="right_card">
                          <i className="fa fa-trash text-danger cur"  onClick={()=>{
                          if(window.confirm("Want to delete?"))
                          {
                            deleteProduct(val.product._id)
                          }
                          
                        }}></i>
                        </div>
                        <img src={val.product.pro_images[0]} alt="" />
                        <div className="p-2">
                          <h4>
                            {val.product.pro_title}
                          </h4>
                          <p className="m-0">
                            {val.product.pro_category}
                          <br />
                          <small className='text-custom'>{val.product.pro_subcategory} </small>
                           
                          </p>
                          <h5 className="mt-1">₹{val.product.pro_price}</h5>
                          <p className="m-0 mt-1">Discount : {val.product.pro_discount}%</p>
                          <h5 className="mt-1"><b>Total Orders</b> : {val.orderCount}</h5>


                          <div className="text-center p-2">
                          <button
                              className="btn btn-outline-danger m-1"
                              onClick={() =>his.push(`/product/${val.product._id}/edit`) }
                            
                            >
                              <i className='fa fa-eye'></i>
                            </button>
                            <button
                              className="btn btn-primary m-1"
                              data-toggle="modal"
                              data-target={`#myyyModal${val.product._id}`}
                              onClick={() => setActiveId(val.product._id)}
                            >
                              Update
                            </button>
                            <div class="modal fade" id={`myyyModal${val.product._id}`}>
                              <div class="modal-dialog modal modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="modal-title">
                                      Update Product Status
                                    </h4>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                  </div>

                                  <div class="modal-body">
                                    <div className="row">
                                      <div className="col-lg-8 col-12 mx-auto">
                                        <form onSubmit={onupdateProductStatus}>
                                          <div class="form-group">
                                            <select
                                              class="form-control "
                                              id="sel1"
                                              value={status}
                                              onChange={(e) =>
                                                setStatus(e.target.value)
                                              }
                                              required
                                            >
                                              <option selected hidden value="">
                                                --Filter--
                                              </option>

                                              <option value="approved">
                                                Approved
                                              </option>
                                              <option value="rejected">
                                                Rejected
                                              </option>
                                              <option value="pending">
                                                Pending
                                              </option>
                                            </select>
                                          </div>
                                          <div className="text-center">
                                            <button
                                              type="submit"
                                              className={
                                                loading
                                                  ? "dis btn btn-primary"
                                                  : "btn btn-primary"
                                              }
                                              disabled={loading}
                                            >
                                              Update Status
                                            </button>
                                          </div>
                                          {loading && (
                                            <div className="text-center p-2">
                                              <CircularProgress
                                                color="error"
                                                size={35}
                                              />
                                            </div>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            
                            <a
                              className="btn btn-outline-danger m-1"
                              href={`${mainpath}/product/${removeSpecialCharacters(val.product.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val.product._id}/DEMO`} target="_blank"
                            
                            >
                              <i className='fa fa-link'></i>
                            </a>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                        </>
                      )
                    })
                   }
                  </div>
    
    </>
  )
}

export default SellerDetails