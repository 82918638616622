import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import { apilink, mainpath, removeSpecialCharacters } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';

const MyListC = ({setPageLoading}) => {
  const alert = useAlert();
  const [categoryList,setCategoryList]= useState([])

  const token = Cookies.get('_indiayour_admin_access_token');

  const [loading, setLoading] = useState(false);
   const [filter, setFilter] = useState("all");
   const [filterCat, setFilterCat] = useState("all");
   const [filterSel, setFilterSel] = useState("all");

   const [productList, setProductList] = useState([]);  
   const [sellerList, setSellerList] = useState([]);

  const [status,setStatus]=  useState('')
  const [activeId,setActiveId]=  useState('')
  const [myData, setMyData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const his = useHistory();
  

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };

  const getAllProducts=async()=>{
    const res=await axios.get(`${apilink}/api/product/getAllProductsAdmin`)
    // console.log(res.data)
    if(res.data.success)
    {
      setProductList(res.data.result)
      setMyData(res.data.result) 
    }
  }

  const getAllCategory=async()=>{
    const res=await axios.get(`${apilink}/api/category/getallCategory`)
    // console.log(res.data)
    if(res.data.success){
      setCategoryList(res.data.result)
    }
  }
  const getallsellers=async()=>{
    const res=await axios.get(`${apilink}/api/seller/getallsellers`)
    // console.log(res.data)
    if(res.data.success){
      setSellerList(res.data.result)
    }
  }

  // useEffect(() => {
  //   if (filter == "all") {
  //     setMyData(productList);
  //   } else {
  //     setMyData(productList.filter((v) => v.isProApproved == filter));
  //   }
  // }, [filter]);

  useEffect(() => {
    let filteredProducts = productList;
  
    // Filter based on 'filter' state
    if (filter != 'all') {
      filteredProducts = filteredProducts.filter((v) => v.isProApproved == filter);
    }
  
    // Filter based on 'filterCat' state
    if (filterCat != 'all') {
      filteredProducts = filteredProducts.filter((v) => v.pro_category == filterCat);
    }
  
    // Filter based on 'filterSel' state
    if (filterSel != 'all') {
      filteredProducts = filteredProducts.filter((v) => v.pro_creator?.comp_name == filterSel);
    }
  
    setMyData(filteredProducts);
  }, [filter, filterCat, filterSel]);


  const onDelete = async (id) => {
    const res = await axios.get(
      `${apilink}/api/product/deleteProductById/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log(res.data)
    if (res.data.success) {
      alert.success(res.data.msg);
      setMyData(productList.filter((product) => product._id !== id));
      getAllProducts()
    } else {
      if (res.data.msg == "Invalid Authentication.") {
        Cookies.remove("_indiayour_admin_access_token");
        localStorage.removeItem("_indiayour_admin_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        alert.error(res.data.msg);
      }
    }
  };

  const onupdateProductStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios.post(
      `${apilink}/api/product/updateStatus`,
      {
        status,
        oid: activeId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    // console.log(res.data)

    if (res.data.success) {
      alert.success(res.data.msg);
      // console.log(activeId);
      const updatedItems = myData.map((item) =>
        item._id == activeId ? {...item, ['isProApproved']: status } : item
      );
      // console.log(updatedItems);
      setStatus("")
      setMyData(updatedItems);
    } else {
      if (res.data.msg == "Invalid Authentication.") {
        Cookies.remove("_indiayour_admin_access_token");
        localStorage.removeItem("_indiayour_admin_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        alert.error(res.data.msg);
      }
    }
    setLoading(false);
  };

  const handelData=()=>{
    setPageLoading(true)
    setTimeout(() => {
      setFilter("all")
    setFilterCat("all")
    setFilterSel("all")
    setMyData(productList);
    setPageLoading(false)
    }, 2000);
  }


useEffect(()=>{
  getAllProducts()
  getAllCategory()
  getallsellers()
},[])


  return (
    <>
      <div className="card p-3">
        <div className="newdd_flex">
        <h5 className="text_Deco">All Products</h5>
          <div className='dflex'>
          <div class="form-group">
            <select
                class="form-control filterform"
                id="sel1"
                value={filterCat}
                onChange={(e) => setFilterCat(e.target.value)}
              >
                <option value="all">All</option>
                {
                  categoryList?.map((val,ind)=>{
                    return(
                      <>
                      <option value={val.cat_name}>{val.cat_name}</option>
                      </>
                    )
                  })
                }
              </select>
            </div>
          <div class="form-group mx-1">
            <select
                class="form-control filterform"
                id="sel1"
                value={filterSel}
                onChange={(e) => setFilterSel(e.target.value)}
              >
                <option value="all">All</option>
                {
                  sellerList?.map((val,ind)=>{
                    return(
                      <>
                      <option value={val.comp_name}>{val.comp_name}</option>
                      </>
                    )
                  })
                }
              </select>
            </div>
            <div class="form-group">
            <select
                class="form-control filterform"
                id="sel1"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
              </select>
            </div>
           <div className='mt0'>
           <button onClick={handelData} className='btn btn-primary p-1 px-2'><i className='fa fa-refresh'></i></button>
           </div>
          </div>
        </div>
        {
                dataall.length > 0 ?(
                  <>
                          <div class="table-responsive ">
          <table class="table table-borderless">
            <thead>
            <tr>
              <th className='w_d'>Date</th>
                <th className="tr_w">Product Name</th>
                <th className="w_30">SellerName</th>
                <th className="w_20">Price</th>
                <th className="w_10">Qty</th>
                <th className="w_20">Status</th>
              </tr>

            </thead>
            <tbody>
              
                  {dataall.map((val, ind) => {
                return (
                  <>
                     <tr className="">
                     <td className="w_d">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>

                      <td className="tr_w">
                        <div className="small_det">
                          <img src={val.pro_images[0]} alt="" />

                          <div>
                            <p>{val.pro_title}</p>
                            <span
                              class="badge badge-primary m-1 cur"
                              onClick={() =>
                                his.push(`/product/${val._id}/edit`)
                              }
                            >
                              View
                            </span>
                            <span
                              class="badge badge-warning m-1 cur"
                              data-toggle="modal"
                              data-target={`#myyyModal/${val._id}`}
                              onClick={() => setActiveId(val._id)}
                            >
                              Update
                            </span>
                            <a href={`${mainpath}/product/${removeSpecialCharacters(val.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/DEMO`} target="_blank" class="badge badge-info m-1 ">Link</a>
                            <div class="modal fade" id={`myyyModal/${val._id}`}>
                              <div class="modal-dialog modal modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="modal-title">
                                      Update Product Status
                                    </h4>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                  </div>

                                  <div class="modal-body">
                                    <div className="row">
                                      <div className="col-lg-8 col-12 mx-auto">
                                        <h5 className="text-center">
                                          Are you Sure About Delete ?{" "}
                                          <span
                                            class="badge badge-warning m-1 cur"
                                            onClick={() => onDelete(val._id)}
                                          >
                                            Delete
                                          </span>{" "}
                                        </h5>
                                        <br />
                                        <form onSubmit={onupdateProductStatus}>
                                          <div class="form-group">
                                            <select
                                              class="form-control "
                                              id="sel1"
                                              value={status}
                                              onChange={(e) =>
                                                setStatus(e.target.value)
                                              }
                                              required
                                            >
                                              <option selected hidden value="">
                                                --Choose--
                                              </option>

                                              <option value="approved">
                                                Approved
                                              </option>
                                              <option value="rejected">
                                                Rejected
                                              </option>
                                              <option value="pending">
                                                Pending
                                              </option>
                                            </select>
                                          </div>
                                          <div className="text-center">
                                            <button
                                              type="submit"
                                              className={
                                                loading
                                                  ? "dis btn btn-primary"
                                                  : "btn btn-primary"
                                              }
                                              disabled={loading}
                                            >
                                              Update Status
                                            </button>
                                          </div>
                                          {loading && (
                                            <div className="text-center p-2">
                                              <CircularProgress
                                                color="error"
                                                size={35}
                                              />
                                            </div>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="w_30"><NavLink to={`/seller/${val.pro_creator?._id}/products`} className="text-custom">
                           {val?.pro_creator?.comp_name}
                          </NavLink></td>
                      <td className="w_20">₹{val.pro_price}</td>
                      <td className="w_10 pl-3">{val.pro_qty}</td>
                      <td className="w_20">
                        {val.isProApproved == "pending" && (
                          <span class="badge badge-warning m-1">Pending</span>
                        )}
                        {val.isProApproved == "approved" && (
                          <span class="badge badge-success m-1">Approved</span>
                        )}
                        {val.isProApproved == "rejected" && (
                          <span class="badge badge-danger m-1">Rejected</span>
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
                  
                  
            </tbody>
          </table>
        </div>
        </>
                ):(
                  <>
                  <div className="text-center p-3">
                  <h5>No Item Found</h5>
                </div>
                  </>
                )
              }
        {
          dataall.length > 0 && <div className="row mt-4">
          <ReactPaginate
            previousLabel={'Prev'}
            nextLabel={'Next'}
            pageCount={boxno}
            onPageChange={likedChange}
            containerClassName={'pagination'}
            // previousLinkClassName={"prevbutton"}
            // nextLinkClassName={"nextbutton"}
            // disabledClassName={"pagedisable"}
            activeClassName={'activebutton'}
          />
        </div>
        }
      </div>
    </>
  );
};

export default MyListC;
