import React, { useEffect, useState } from 'react'
import SideBar from '../../component/sidebar/SideBar'
import SellerList from '../../component/sellercompo/SellerList'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
import { apilink } from '../../data/fdata';
import axios from 'axios';

const SellerDet = () => {
  const his = useHistory();
  
  const [pageloading, setPageLoading] = useState(false);
  const [sellerList, setSellerList] = useState([]);

  const token = Cookies.get('_indiayour_admin_access_token');


  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            
           

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);

  const getallsellers=async()=>{
    const res=await axios.get(`${apilink}/api/seller/getallsellers`)
    // console.log(res.data)
    if(res.data.success){
      setSellerList(res.data.result)
    }
  }

  useEffect(()=>{
    getallsellers()
  },[])
  return (
    <>
     <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              
              <div className="row mt-5">
              <div className="col-lg-12 col-md-12 mb-3">
                {
                  sellerList.length > 0 && <SellerList token={token} sellerList={sellerList} />
                }
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    
    
    </>
  )
}

export default SellerDet