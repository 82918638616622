import React, { useEffect, useState } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useAlert } from 'react-alert';
import './Category.css'
import { CircularProgress, Tooltip } from '@mui/material';
import DynamicInputFields from './DynamicInputFields';

const Category = () => {
    const [pageloading, setPageLoading] = useState(false);
    const [categoryPop,setCategoryPop]=useState(false);
    const [cat_name,setCat_Name]=useState("")
    const [cat_img,setCat_Img]=useState([])
    const [loading, setLoading] = useState(false);

    const [type, setType] = useState("");
    const [catID, setCatID] = useState("");
    const [catimg, setCatimg] = useState("");
   
    const [subCatDetails, setSubCatDetails] = useState([{ subcat_name: '' }]);



    const [categoryList,setCategoryList]=useState([])

    const alert = useAlert();

    const token = Cookies.get('_indiayour_admin_access_token');
    const his = useHistory();

    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              if(!res.data.success)
              {
                Cookies.remove('_indiayour_admin_access_token');
               localStorage.removeItem('_indiayour_admin_access_login');
              console.clear();
              window.location.href = '/';
              }else{
                
                // setUserData(res.data.userInfo)
    
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 2000);
             
        }else{
          his.push("/")
        }
      }, []);
      const handelFile = (e) => {
        const { files } = e.target;
       
        // console.log(files[0]); files[0].type === 'application/pdf'
        if (files.length > 0) {
          if (files[0].size > 1000000) {
            alert.error(` File should be less then 1 MB`);
           
          } else if (
            files[0].type === "image/jpeg" ||
            files[0].type === "image/jpg" ||
            files[0].type === "image/png"
          ) {
           
            setCat_Img(files[0]);
           
          } else {
            
            alert.error(`File extension should be only jpg, png , jpeg`);
          }
         
        }
        
      };
      const uploadCategory=async(e)=>{
        e.preventDefault();
        setLoading(true)
        let formData = new FormData();
       
        formData.append("file", cat_img);
      
        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        });
        if(res.data.success)
        {
          const data={
           cat_name,
           cat_image:res.data.result[0].Location
      
        }
        // console.log(data)
        const ress=await axios.post(`${apilink}/api/category/addCategory`,data,{
          headers: {
            
            Authorization: token,
          },
        })
        if(ress.data.success)
        {
          alert.success("Category added successfully")
          setCat_Img([])
          setCat_Name("")
          getAllCategory()
          setCategoryPop(false)
          document.querySelector(".main_div").classList.remove("disable-scroll");          

        }else{
          alert.error(ress.data.msg)
          setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");  
        }

        }else{
          alert.error(res.data.msg)
          setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");  
        }

        setLoading(false)
      }
      const updateCategory=async(e)=>{
        e.preventDefault()
        setLoading(true)

       if(cat_img.name)
       {
        let formData = new FormData();
       
        formData.append("file", cat_img);
      
        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        });
        if(res.data.success)
        {
          const data={
            cat_name,
            cat_image:res.data.result[0].Location,
            catID
         }
         const ress=await axios.post(`${apilink}/api/category/updateCategory`,data,{
          headers: {
            
            Authorization: token,
          },
        })
        if(ress.data.success)
        {
          alert.success(ress.data.msg)
          setCat_Img([])
          setCat_Name("")
          setCatID("")
          getAllCategory()
          setCategoryPop(false)
          document.querySelector(".main_div").classList.remove("disable-scroll");          

        }else{
          alert.error(ress.data.msg)
          setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");  
        }

        }else{
          
          alert.error(res.data.msg)
          setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");  
        }

       }else{
        const data={
          cat_name,
          cat_image:catimg,
          catID
     
       }
       const ress=await axios.post(`${apilink}/api/category/updateCategory`,data,{
        headers: {
          
          Authorization: token,
        },
      })
      if(ress.data.success)
      {
        alert.success(ress.data.msg)
        setCat_Img([])
        setCat_Name("")
        setCatID("")
        getAllCategory()
        setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");          

      }else{
        alert.error(ress.data.msg)
        setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");  
      }
       }


        setLoading(false)
      }

      const updateSubCategory=async(e)=>{
        e.preventDefault()
        setLoading(true)
        const data={
          subcat_name:subCatDetails,
          cat_id:catID
       }
       console.log(data)
       const res=await axios.post(`${apilink}/api/category/updateCategoryById`,data,{
        headers: {
          
          Authorization: token,
        },
      })
      if(res.data.success)
      {
        alert.success(res.data.msg)
        setCat_Img([])
        setCat_Name("")
        setCatID("")
        setSubCatDetails([{ subcat_name: '' }])
        getAllCategory()
        setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");          

      }else{
        alert.error(res.data.msg)
        setCategoryPop(false)
        document.querySelector(".main_div").classList.remove("disable-scroll");  
      }
        setLoading(false)
      }

      const getAllCategory=async()=>{
        const res=await axios.get(`${apilink}/api/category/getallCategory`)
        if(res.data.success)
        {
          setCategoryList(res.data.result)
        }
      }

      useEffect(()=>{
        getAllCategory()
      },[])
    
  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="new_dd_flex mb-4">
              <h5 className="text_Deco">Category</h5>
              <button className='btn btn-primary' onClick={()=>{
                document
                .querySelector(".main_div")
                .classList.add("disable-scroll");
                setCategoryPop(true)
                setType("ADD")
                }}>Add Category</button>
                </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 mb-3">
             <div className="row">
              {
                categoryList.map((val,ind)=>{
                  return(
                    <>
                    
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6  mb-3">
               <div className="card p-2">
               <img src={val.cat_image} alt="" className='cat_img' />
               <p className='fn_14 text-custom text-center mt-3 m-0'>{val.cat_name}</p>
               <div className="text-right">
               <Tooltip title="Add SubCategory"><i onClick={()=>{
                document
                .querySelector(".main_div")
                .classList.add("disable-scroll");
                setCategoryPop(true)
                setType("ADDSUB")
                setCatID(val._id)
                setSubCatDetails(val.sub_category)
                
                }} className='fa fa-plus text-danger cur'></i></Tooltip>  &nbsp; &nbsp;
                 <Tooltip title="Edit Category">
                 <i onClick={()=>{
                document
                .querySelector(".main_div")
                .classList.add("disable-scroll");
                setCategoryPop(true)
                setType("EDIT")
                setCatID(val._id)
                setCat_Name(val.cat_name)
                setCatimg(val.cat_image)
                }} className='fa fa-edit text-danger cur'></i>
                 </Tooltip>
                
               </div>
               </div>
              </div>
                    </>
                  )
                })
              }
             </div>
              </div>
            </div>
           

          </div>
        </div>
      </div>
      

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
      {
        categoryPop && (
          <>
<div className="model_box">
  <div className="model-up">
  <div className="inner_model">
    <div className="cross" onClick={()=>{
      setCategoryPop(false)
      document.querySelector(".main_div").classList.remove("disable-scroll");
      setType("")
      setCat_Name("")
      setCat_Img([])
      setCatID("")
    }}> &times; </div>
    {
      type=="ADD" &&(
        <>
        <form onSubmit={uploadCategory} >
    <div className="form-group ">
                        <label for="address">Category Title:</label>
                        <input
                          type="text"
                          placeholder="Enter Category Title"
                          className="form-control form_h"
                          name="cat_name"
                          value={cat_name}
                          onChange={(e) => setCat_Name(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group ">
                        <label htmlFor="">Select Category Image</label>
                        <input
                          type="file"
                          className="form-control form_h"
                          name="imgfile"
                          multiple
                          accept=".png, .jpeg , .jpg"
                          required
                          onChange={handelFile}
                        />
                      </div>
                      <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} color="error" />
                      </div>
                    )}
    </form>
        
        </>
      )
    }
    {
      type == "EDIT" && (
        <>
        <form onSubmit={updateCategory} >
    <div className="form-group ">
                        <label for="address">Category Title:</label>
                        <input
                          type="text"
                          placeholder="Category Title"
                          className="form-control form_h"
                          name="cat_name"
                          value={cat_name}
                          onChange={(e) => setCat_Name(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group ">
                        <label htmlFor="">Select Category Image: {type=="EDIT" && <a target='_blank' href={catimg} className='text-custom cur'>view</a>} </label>
                        <input
                          type="file"
                          className="form-control form_h"
                          name="imgfile"
                          multiple
                          accept=".png, .jpeg , .jpg"
                          
                          onChange={handelFile}
                        />
                      </div>
                      <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Update Category
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} color="error" />
                      </div>
                    )}
    </form>
        </>
      )
    }
    {
      type=="ADDSUB" && (
        <>
        <h5 className='text-center p-3'>SubCategory Details</h5>
        <form onSubmit={updateSubCategory} >
        <DynamicInputFields subCatDetails={subCatDetails} setSubCatDetails={setSubCatDetails} />
                      
                      <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-2 pr-2"
                            : "btn btn-primary pl-2 pr-2"
                        }
                        disabled={loading}
                      >
                        Update Category
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} color="error" />
                      </div>
                    )}
    </form>
        </>
      )
    }
  </div>
  </div>
</div>

          </>
        )
      }



    </>
  )
}

export default Category