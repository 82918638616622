import React, { useEffect, useState } from 'react';
import '../dashboard/Dashboard.css';
import { useHistory , NavLink } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import ReactPaginate from 'react-paginate';
import { useAlert } from 'react-alert';

const Query = () => {
  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const token = Cookies.get('_indiayour_admin_access_token');
  const alert= useAlert()
  const [loading, setLoading] = useState(false);
   const [filter, setFilter] = useState("All");
   const [conList, setConList] = useState([]);  
  const [myData, setMyData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

 
  

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            
           

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);


  const getAllCon=async()=>{
    const res=await axios.get(`${apilink}/api/contact/getContacts`)
    // console.log(res.data)
    if(res.data.success)
    {
      setConList(res.data.result)
      setMyData(res.data.result) 
    }
  }

  const updateStatus=async(id)=>{
    setPageLoading(true)
    const res=await axios.get(`${apilink}/api/contact/updateContact/${id}`,{
        headers: {
            Authorization: token,
          },
    })
    // console.log(res.data)
    if(res.data.success){
        getAllCon()
        setTimeout(() => {
            alert.success(res.data.msg)
        
        setPageLoading(false)
        }, 2000);

    }else{
        setTimeout(() => {
            alert.error(res.data.msg)
        setPageLoading(false)
        }, 2000);
    }
    

  }
const deleteContact=async(id)=>{
    
    setPageLoading(true)
    const res=await axios.get(`${apilink}/api/contact/deleteContact/${id}`,{
        headers: {
            Authorization: token,
          },
    })
    // console.log(res.data)
    if(res.data.success){
        getAllCon()
        setTimeout(() => {
            alert.success(res.data.msg)
       
        setPageLoading(false)
        }, 2000);

    }else{
        setTimeout(() => {
            alert.error(res.data.msg)
        setPageLoading(false)
        }, 2000);
    }
    
}
  useEffect(()=>{
    getAllCon()
    
  },[])

  useEffect(()=>{

    if(filter=="All"){
      
      setMyData(conList);
    } else{
      setMyData(conList.filter(v=>v.isChecked== (filter=="approved")));
    }

  },[filter])
  

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              {/* <h3 className="text_Deco ml-2">All Products</h3> */}
              <div className="row mt-2 p-2">
                <div className="col-lg-12 col-md-12 mb-3">
                <div className="card p-3">
        <div className="newdd_flex">
        <h5 className="text_Deco">All Queries</h5>
          <div className='dflex'>
          
         
            <div class="form-group">
            <select
                class="form-control filterform"
                id="sel1"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="All">All</option>
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>
              </select>
            </div>
           
          </div>
        </div>
        {
                dataall.length > 0 ?(
                  <>
                          <div class="table-responsive ">
          <table class="table table-borderless">
            <thead>
            <tr>
              <th className='w_d'>Date</th>
                <th className="tr_w">Name</th>
                <th className="w_20">Phone</th>
                <th className="w_com">Message</th>
                
                <th className="w_20">Status</th>
              </tr>

            </thead>
            <tbody>
              
                  {dataall.map((val, ind) => {
                return (
                  <>
                     <tr className="">
                     <td className="w_d">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>

                      <td className="tr_w">
                       {`${val.fname} ${val.lname}`}
                      </td>
                     
                      <td className="w_20"><a href={`tel:${val.phone}`}>{val.phone}</a></td>
                      <td className="w_com">
                        <p ><span className='qutext'>{val.comment}</span>   </p>
                      </td>
                      <td className="w_20">
                        {!val.isChecked ?(
                          <span class="badge badge-warning m-1 cur" onClick={()=>updateStatus(val._id)}>Pending</span>
                        ): <span class="badge badge-success m-1">Approved</span>}
                        
                        &nbsp;
                        <i className='fa fa-trash cur text-custom' onClick={()=>deleteContact(val._id)}></i>
                        &nbsp;
                        <i className='fa fa-eye ml-2 cur text-primary' data-toggle="modal"
                            data-target={`#myyModal${val._id}`} ></i>
                        <div class="modal fade" id={`myyModal${val._id}`}>
                            <div class="modal-dialog modal modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">Inquery Details</h4>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                </div>

                                <div class="modal-body">
                                {val.comment}
                                </div>
                              </div>
                            </div>
                          </div>

                      </td>
                    </tr>
                  </>
                );
              })}
                  
                  
            </tbody>
          </table>
        </div>
        </>
                ):(
                  <>
                  <div className="text-center p-3">
                  <h5>No Item Found</h5>
                </div>
                  </>
                )
              }
        {
          dataall.length > 0 && <div className="row mt-4">
          <ReactPaginate
            previousLabel={'Prev'}
            nextLabel={'Next'}
            pageCount={boxno}
            onPageChange={likedChange}
            containerClassName={'pagination'}
            // previousLinkClassName={"prevbutton"}
            // nextLinkClassName={"nextbutton"}
            // disabledClassName={"pagedisable"}
            activeClassName={'activebutton'}
          />
        </div>
        }
      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default Query;
