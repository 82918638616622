import React, { useEffect, useState } from 'react';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import Error from './pages/Error';
import Cookies from 'js-cookie';
import './App.css';
// import Navbar from './component/navbar/NavBar';
import NavBar from './component/navbar/NavBar';
import LoginU from './pages/auth/LoginU';


import Footer from './component/footer/Footer';

import Home from './pages/home/Home';
import AccountDet from './pages/dashboard/AccountDet';

import MyList from './pages/dashboard/MyList';
import Setting from './pages/dashboard/Setting';

import OrderRequest from './pages/dashboard/OrderRequest';
import SellerDet from './pages/dashboard/SellerDet';
import UserDet from './pages/dashboard/UserDet';
import ViewSellerByID from './pages/dashboard/ViewSellerByID';
import ViewUserByID from './pages/dashboard/ViewUserByID';
import EditItem from './pages/dashboard/EditItem';
import ProductSellDetails from './pages/dashboard/ProductSellDetails';
import AboutUs from './pages/needpages/AboutUs';
import ContactUs from './pages/needpages/ContactUs';
import PrivacyPolicy from './pages/needpages/PrivacyPolicy';
import TermCondition from './pages/needpages/TermCondition';
import SiteMap from './pages/needpages/SiteMap';
import Category from './pages/category/Category';
import Banner from './pages/banner/Banner';
import Ads from './pages/banner/Ads';
import Query from './pages/needpages/Query';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';

const App = () => {
  const his = useHistory();
  const loc = useLocation();
  const [loginstaus, setLoginStatus] = useState(false);
  // console.log(loc);
  const hecord_tokon = Cookies.get('_hecord_access_user_tokon_');
  const [userDetails, setUserDetails] = useState([]);


  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [loc]);

  return (
    <>
      <NavBar />
      <Switch>
       
        <Route exact path="/" component={LoginU} />
        <Route exact path="/home" component={Home} />
       
        <Route exact path="/products" component={MyList} />
        <Route exact path="/product/:proid/edit" component={EditItem} />
        
        <Route exact path="/setting" component={Setting} />
        <Route exact path="/account" component={AccountDet} />
        <Route exact path="/seller" component={SellerDet} />
        <Route exact path="/seller/:sellerid/products" component={ViewSellerByID} />
        <Route exact path="/user" component={UserDet} />
        <Route exact path="/user/:userid/orders" component={ViewUserByID} />
        <Route exact path="/product-sell-details" component={ProductSellDetails} />     
        <Route exact path="/order-requests" component={OrderRequest} />

        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-conditions" component={TermCondition} />
        <Route exact path="/sitemap-settings" component={SiteMap} />
        <Route exact path="/category" component={Category} />
        <Route exact path="/banner" component={Banner} />
        <Route exact path="/ads" component={Ads} />
        <Route exact path="/query" component={Query} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/reset-password/:accesstoken" component={ResetPassword} />





        
        <Route component={Error} />2
      </Switch>
      <Footer />
    </>
  );
};

export default App;
