import React, { useEffect, useState } from 'react'
import SideBar from '../../component/sidebar/SideBar'
import UserDetails from '../../component/usercompo/UserDetails'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
import { apilink } from '../../data/fdata';
import axios from 'axios';

const ViewUserByID = () => {
  const his = useHistory();
  
  const [pageloading, setPageLoading] = useState(false);
  const [userOrderList, setUserOrderList] = useState([]);
  const [userData, setUserData] = useState({});


  const token = Cookies.get('_indiayour_admin_access_token');
const {userid}=useParams()

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            
           

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);

  const getuserById=async()=>{
    const res=await axios.get(`${apilink}/api/user/getuserById/${userid}`)
    // console.log(res.data)
    if(res.data.success){
      setUserData(res.data.result)
    }
  }
  const getOrdersByUserIdByAdmin=async()=>{
    const res=await axios.get(`${apilink}/api/order/getOrdersByUserIdByAdmin/${userid}`,{
      headers: {
        Authorization: token,
      },
    })
    // console.log(res.data)
    if(res.data.success){
      setUserOrderList(res.data.result)
    }
  }

  useEffect(()=>{
    if(userid)
    {
      getuserById()
      getOrdersByUserIdByAdmin()
    }
    
  },[userid])
  return (
    <>
     <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              
            <div className="row ">
                <div className="col-12">
                  <div className="card p-3">
                    <h3 className="text_Deco mb-3">User Account Details</h3>
                    <h5>Name : <span className='text-custom'>{userData?.name}</span></h5>
                    <h5>Email: <span className='text-custom'>{userData?.email}</span></h5>
                    <h5>Phone: <a className='text-custom' href={`tel:${userData?.phone}`}>{userData?.phone}</a></h5>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                {
                  userOrderList?.length >0 ?(
                    <>
                    <UserDetails userOrderList={userOrderList} />
                    </>
                  ):(
                    <>
                    <div className="text-center">
                      <div className="p-3">
                        Not Yet Placed Any Order
                      </div>
                    </div>
                    </>
                  )
                }
              
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  )
}

export default ViewUserByID