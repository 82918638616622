import React from 'react'
import { LineChart, Line , ResponsiveContainer, Tooltip, Legend} from 'recharts';


const Linechart = ({data,type}) => {
  return (
    <>
    <ResponsiveContainer width={100} height={100}>
    <LineChart   data={data.data}>
    <Tooltip
                contentStyle={{ background: "transparent", border: "none" }}
                labelStyle={{ display: "none" }}
                position={{ x: 10, y: 50 }}
              />
               
      <Line type="monotone" dataKey={data.type} stroke="#C0252D" strokeWidth={2} 
                dot={false} />
    </LineChart>
    </ResponsiveContainer>
   
    </>
  )
}

export default Linechart