import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { gstPriceFunc, path } from '../../data/fdata';
import Tooltip from '@mui/material/Tooltip';

const UserDetails = ({userOrderList}) => {
  const [myData, setMyData] = useState(userOrderList);
  const [filter, setFilter] = useState("All");

  const [pageNo, setPageNo] = useState(0);
  const perpage = 5;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };

  const handelProduct=(e)=>{
    setFilter(e.target.value)
    if(e.target.value=="All")
    {
      
      setMyData(userOrderList)
      
    }else{
      setMyData(userOrderList.filter((v)=>v.orderStatus == e.target.value))
    }
    
  }
  return (
    <>
      <div className="col-lg-12 col-md-12 mb-3">
        <div className="card p-3">
          <div className="dd_flex">
            <h5 className="text_Deco">Order Requests</h5>
            <div>
              <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={handelProduct}>
                  <option selected hidden value="">
                    --Filter--
                  </option>
                  <option value="All">All</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                  <option value="Rejected">Rejected</option>

                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive ">
            <table class="table table-borderless">
              <thead>
                <tr>
                <th className="tr_w">Product Det</th>

<th className='w_10'>Qty</th>
<th className='w_10' title="This is added discount">
Discount
  </th>

<th className='tr_w'>Customer</th>
<th className='w_20'>UserName</th>
<th className='w_20'>Contact</th>
<th className='w_20'>Status</th>
                </tr>
              </thead>
              <tbody>
              {dataall.map((val, ind) => {
                  return (
                    <>
                      <tr className="">
                        <td className="tr_w">
                          <div className="small_det">
                          {val?.order_product?.pro_images.length > 0 && (
                          <img
                            src={val?.order_product?.pro_images[0]}
                            alt=""
                            draggable="false"
                            
                          />
                        )}

<div>
                              <p>{val?.order_product?.pro_title}</p>
                              <h5 className="ml-2">₹{val.order_price}</h5>
                            </div>
                          </div>
                         
                        </td>

                        <td className="w_10 pl-3">{val?.order_qty}</td>
                        <td className="w_10 pl-3" title="This is added discount">{val?.added_discount}%</td>
                        <td className="tr_w">
                          <p>
                           {val?.order_customer}
                          </p>
                        </td>
                        <td className="w_20">{val?.order_name}</td>
                        <td className="w_20"><a href={`tel:${val?.order_phone}`}>{val?.order_phone}</a></td>

                        <td className="w_20">
                        { val?.orderStatus =="Requested" && <span className='badge badge-warning'>Requested</span> } { val?.orderStatus =="Rejected" && <span className='badge badge-danger'>Rejected</span> }  { (val?.orderStatus =="Confirmed" || val?.orderStatus=="Shipped" || val?.orderStatus=="Delivered" ) && <span className='badge badge-success'>{val?.orderStatus}</span> }
                        <span
                            class="badge badge-primary m-1 cur"
                            data-toggle="modal"
                            data-target={`#myyModal${val._id}`}
                          >
                            View
                          </span>
                          <div class="modal fade" id={`myyModal${val._id}`}>
                            <div class="modal-dialog modal-lg modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">Request Details</h4>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                </div>

                                <div class="modal-body">
                                  <div className="row">
                                    <div className="col-md-6 col-12 mx-auto mb-3">
                                    <div className="img_flex">
                                          {val?.order_product?.pro_images?.map((va, indd) => {
                                            return (
                                              <div className="img_flex_item" key={indd}>
                                                <img src={va} alt="" />
                                              </div>
                                            );
                                          })}
                                            </div>
                                    </div>
                                    <div className="col-md-6 col-12 mx-auto mb-3">
                                      <h3>{val?.order_product?.pro_title}</h3>
                                      <p className="m-0 mb-3">
                                      { val?.order_product?.pro_category} &nbsp; / &nbsp; { val?.order_product?.pro_subcategory}
                                      </p>
                                     

                                      <p className="m-0">
                      <b>Product Price: </b> &nbsp;{" "}
                      {val?.order_product?.pro_price} X{" "}
                      {val?.order_qty} ={" "}
                      {val?.order_product?.pro_price * val?.order_qty}{" "}
                    </p>
                    <p className="m-0">
                      <b>Discount: </b> &nbsp;{" "}
                      {val?.order_product?.pro_discount}%
                    </p>
                    <p className="m-0">
                      <b>Discount Price: </b> &nbsp; ₹ {val?.order_price}
                    </p>
                    <p className="m-0">
                      <b>GST: </b> &nbsp; 
                      {val?.order_product?.pro_gst_percentage}%
                    </p>
                    <p className="m-0">
                      <b>Total Amount: </b> &nbsp; ₹{" "}
                      {val?.order_qty * (gstPriceFunc(val?.order_product?.pro_price,val?.order_product?.pro_discount,val?.order_product?.pro_gst_percentage))
                        }
                    </p>



                    

                  


                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12 mb-3">
                                    <p class="m-0 fn_18"><b>BILL TO</b></p>
                        <p class="m-0 "><b>{val?.order_customer}</b>
                            </p>
                           
                            <p class="m-0">{val?.order_name}</p>
                            <p class="m-0">{val?.billing_address?.b_address}</p>
                            <p class="m-0">{val?.billing_address?.b_city} , {val?.billing_address?.b_district} , {val?.billing_address?.b_state}</p>
                            <p class="m-0 ">India</p>
                            <p class="m-0">{val?.order_phone}</p>
                            <p class="m-0"> {val?.order_email}</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 mb-3">
                                    <p class="m-0 fn_18"><b>SHIP TO</b></p>
                    <p class="m-0 "><b>{val?.order_customer}</b>
                            </p>
                           
                            <p class="m-0">{val?.order_name}</p>
                            <p class="m-0">{val?.shipping_address?.s_address}</p>
                            <p class="m-0">{val?.shipping_address?.s_city} , {val?.shipping_address?.s_district} , {val?.shipping_address?.s_state}</p>
                            <p class="m-0 ">India</p>
                            <p class="m-0">{val?.order_phone}</p>
                            <p class="m-0"> {val?.order_email}</p>

</div>
                                  </div>
                                  <div className="row mt-4">
                                    <div className="col-12">
                                    <h5>Seller Details</h5>
                                    <p className='m-0'><b>Company Name:</b> {val?.order_product?.pro_creator?.comp_name}</p>
                                    <p className='m-0'><b>Phone: </b> <a href={`tel:${val?.order_product?.pro_creator?.phone}`}>{val?.order_product?.pro_creator?.phone}</a> </p>
                                    
                                    <p className='m-0'><b>Email: </b> {val?.order_product?.pro_creator?.email}</p>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          {
            dataall.length > 0 && <div className="row mt-3">
            <ReactPaginate
              previousLabel={'Prev'}
              nextLabel={'Next'}
              pageCount={boxno}
              onPageChange={likedChange}
              containerClassName={'pagination'}
              // previousLinkClassName={"prevbutton"}
              // nextLinkClassName={"nextbutton"}
              // disabledClassName={"pagedisable"}
              activeClassName={'activebutton'}
            />
          </div>
          }
        </div>
      </div>
    </>
  );
};




export default UserDetails