import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { CircularProgress } from '@mui/material';
import { useAlert } from 'react-alert';

const Setting = () => {
  const alert = useAlert();
  const token = Cookies.get('_indiayour_admin_access_token');

  const [otype, setOtype] = useState("password");
  const [ntype, setNtype] = useState("password");
  const [ctype, setCtype] = useState("password");

  const [nPass, setNPass] = useState('');
  const [cPass, setCPass] = useState('');
  const [oPass, setOPass] = useState('');

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [pageloading, setPageLoading] = useState(false);


  const his = useHistory();



  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            // if(!res.data.userInfo.isApproved) {
            //     his.push("/business/add")
            // }
            

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);

    if(nPass.length <6){
      alert.error("Password length must be at least 6")
    }else{
      if(nPass==cPass)
    {
      const data={
        password:oPass,
        npassword:nPass,
      }
  
      const res=await axios.post(`${apilink}/api/admin/updateAdminPassword`,data,{
        headers: {
          Authorization: token,
        },
      })
      if(res.data.success)
      {
        alert.success(res.data.msg)
        setTimeout(()=>{
          Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
        },2500)

      }else{
        alert.error(res.data.msg)
      }

    }else{
      alert.error("Password not matched")
    }

    }

    setLoading(false);
  };
  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <h3 className="text_Deco">Profile Setting</h3>
              <div className="row mt-5 p-2">
                <div className="col-lg-6 col-md-8 col-12">
                  <div className="card p-3">
                  {status ? (
                  <>
                    <div class="alert alert-warning alert-dismissible">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {msg}
                    </div>
                  </>
                ) : null}

                <h5 className=" pb-2">Update Password</h5>
                <br />
                    <form onSubmit={onSub}>
                    <div className="form-group passform">
                        <input
                          type={otype}
                          placeholder="Enter Old Password"
                          className="form-control form_h"
                          name="opassword"
                          value={oPass}
                          onChange={(e) => setOPass(e.target.value)}
                          required
                        />
                         <div>
                     {otype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setOtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setOtype("password")}></i> }
                     </div>
                      </div>
                      <div className="form-group passform">
                        <input
                          type={ntype}
                          placeholder="Enter New Password"
                          className="form-control form_h"
                          name="npassword"
                          value={nPass}
                          onChange={(e) => setNPass(e.target.value)}
                          required
                        />
                        <div>
                     {ntype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setNtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setNtype("password")}></i> }
                     </div>
                      </div>
                      <div className="form-group passform">
                        <input
                          type={ctype}
                          placeholder="Confirm Password"
                          className="form-control form_h"
                          name="cpassword"
                          value={cPass}
                          onChange={(e) => setCPass(e.target.value)}
                          required
                        />
                         <div>
                     {ctype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setCtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setCtype("password")}></i> }
                     </div>
                      </div>
                      <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                      Update Password
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                    </form>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
