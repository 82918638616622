import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useAlert } from 'react-alert';
import './Need.css'

import React, { useEffect, useState } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { apilink, path } from '../../data/fdata';
import EditorCompo from './EditorCompo';

const AboutUs = () => {
  const [pageloading, setPageLoading] = useState(false);
  const alert = useAlert();
  const token = Cookies.get('_indiayour_admin_access_token');
  const his = useHistory();
  const [userData, setUserData] = useState([]);

  useEffect(async() => {
      if(token) {
        setPageLoading(true)
          const res=await axios.get(`${apilink}/api/admin/authVerify`,{
              headers: {
                Authorization: token,
              },
            })
            if(!res.data.success)
            {
              Cookies.remove('_indiayour_admin_access_token');
             localStorage.removeItem('_indiayour_admin_access_login');
            console.clear();
            window.location.href = '/';
            }else{
              
              setUserData(res.data.userInfo)
  
            }
            setTimeout(() => {
              setPageLoading(false)
            }, 2000);
           
      }else{
        his.push("/")
      }
    }, []);

  return (
    <>
     <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          
<EditorCompo ctype="ABOUTUS" ctext="About Us Content" setPageLoading={setPageLoading} />           

          </div>
        </div>
      </div>
      

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    
    </>
  )
}

export default AboutUs