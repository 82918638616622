import React, { useEffect, useState } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useAlert } from 'react-alert';
import './Banner.css'
import { CircularProgress } from '@mui/material';

const Banner = () => {
    const [pageloading, setPageLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [blink, setBLink] = useState("");
    const [bannerImg, setBannerImg] = useState([]);
    const [bannerList,setBannerList]=useState([]);
    const [categoryList,setCategoryList]=useState([])
    const alert = useAlert();

    const token = Cookies.get('_indiayour_admin_access_token');
    const his = useHistory();

    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              if(!res.data.success)
              {
                Cookies.remove('_indiayour_admin_access_token');
               localStorage.removeItem('_indiayour_admin_access_login');
              console.clear();
              window.location.href = '/';
              }else{
                
                // setUserData(res.data.userInfo)
    
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 2000);
             
        }else{
          his.push("/")
        }
      }, []);

  


  const getallCategory = async()=>{
    const res=await axios.get(`${apilink}/api/category/getallCategory`)
    if(res.data.success)
    {
      setCategoryList(res.data.result)
    }
  }


      const uploadBanner=async(e)=>{
        e.preventDefault()
        setLoading(true)

       

        let formData = new FormData();
       
        formData.append("file", bannerImg);
      
        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        });
        // console.log(res.data)
       
        if(res.data.success)
        {
        //   let imgarr=[];
        // res.data.result.forEach(img=>{
        //   imgarr.push(img.Location);
        // })

        
          const data={
            bannerCatID:blink,
              bannerImg:res.data.result[0].Location
        
          }
          // console.log(data)
          const ress=await axios.post(`${apilink}/api/banner/postBanner`,data,{
            headers: {
              
              Authorization: token,
            },
          })
          if(ress.data.success)
          {
            
            alert.success("Banner added successfully")
            setBannerImg([])
            setBLink("")
            getAllBanner()
           
          //   setTimeout(() => {
          //     window.location.reload()
          //   }, 2000);
          }else{
            alert.error(ress.data.msg)
      
          }
        
          
      
        }else{
          alert.error(res.data.msg)
        }

        setLoading(false)

      }

       const handelFile = (e) => {
        const { files } = e.target;
       
        // console.log(files[0]); files[0].type === 'application/pdf'
        if (files.length > 0) {
          if (files[0].size > 1000000) {
            alert.error(` File should be less then 1 MB`);
           
          } else if (
            files[0].type === "image/jpeg" ||
            files[0].type === "image/jpg" ||
            files[0].type === "image/png"
          ) {
           
            setBannerImg(files[0]);
           
          } else {
            
            alert.error(`File extension should be only jpg, png , jpeg`);
          }
         
        }
        
      };

      const getAllBanner=async()=>{
        const res=await axios.get(`${apilink}/api/banner/getAllBanner`)
        // console.log(res.data)
    
        if(res.data.success){
            setBannerList(res.data.result)
    
        }else{
            alert.error(res.data.msg)
        }
      }



      useEffect(()=>{
        getAllBanner()
        getallCategory()
      },[])

      const deleteBanner=async(id)=>{

        const res=await axios.get(`${apilink}/api/banner/deleteBanner/${id}`,{
            headers: {
        
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success)
        {
            alert.success(res.data.msg)
            let ar=bannerList.filter((v)=>v._id != id)
            setBannerList(ar)
        }else{
            alert.error(res.data.msg)
        }
      }

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="row mt-4">
             <div className="col-12">
             <div className="new_dd_flex">
              <h5 className="text_Deco">Banners</h5>
              <button className='btn btn-primary' data-toggle="modal"
                            data-target='#myModal'> <i className='fa fa-cloud-upload'></i> Upload Banners</button>


              <div class="modal fade" id='myModal'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Upload Gallery</h4>
              <button type="button" class="close" data-dismiss="modal" onClick={()=>{
                 setBannerImg([])
                 setBLink("")
              }}>
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={uploadBanner}>

                {/* <div className="form-group ">
                <label>Select Category</label>
                        <select
                          class="form-control form_h"
                          value={blink}
                         onChange={(e) => setBLink(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category-
                          </option>
                          {categoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val._id}>
                                  {val.cat_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                       
                     </div> */}

<div className="form-group ">
                       <label for="address">Banar Link:</label>
                       <input
                         type="text"
                         placeholder="Enter Link"
                         className="form-control form_h"
                         name="blink"
                         value={blink}
                         onChange={(e) => setBLink(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Upload Banner Image:</label>
                       <input
                         type="file"                         
                         className="form-control form_h"
                         name="image"
                        
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelFile}
                         required
                       />
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>

              </div>
             </div>
             <div className="col-12 mx-auto">
            
           {
            bannerList.length > 0 ? (
              <>
               <div className="banner_flex mt-5">
              {
                bannerList.map((val,ind)=>{
                  return(
                    <>

<div className="banner_flex_item " key={ind} >
                                  
                                  <img src={val.bannerImg} alt="" />
                         
                         
                          <div className="delete_btn" onClick={()=>deleteBanner(val._id)}>
                              <i className='fa fa-trash' ></i>       
                          </div>

                      </div>
                    </>
                  )
                })
              }
              </div>
              </>
            ):(
              <>
              <div className="text-center mt-5 ">
                <p>No Item Found</p>
              </div>
              </>
            )
           }
                        
           
             </div>
             
            </div>
           
            

          </div>
        </div>
      </div>
      

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  )
}

export default Banner