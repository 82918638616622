import React, { useEffect, useState } from 'react';
import '../dashboard/Dashboard.css';
import { NavLink, useHistory } from 'react-router-dom';
import { CategoryList, apilink, path } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useAlert } from 'react-alert';



import Request from '../../component/homecompo/Request';
import MyListCompo from '../../component/homecompo/MyListCompo';

import Linechart from '../../component/chart/planline/Linechart';
import Piechart from '../../component/chart/pie/Piechart';


const datalist={
  type:"users",
  data:[
    { name: "Sun", users: 400 },
      { name: "Mon", users: 600 },
      { name: "Tue", users: 500 },
      { name: "Wed", users: 700 },
      { name: "Thu", users: 400 },
      { name: "Fri", users: 500 },
      { name: "Sat", users: 450 },
  ]
}

const Home = () => {
 
  const alert = useAlert();

  const token = Cookies.get('_indiayour_admin_access_token');
  const his = useHistory();
  const [userData, setUserData] = useState([]);
  const [productList, setProductList] = useState([]);  
  const [myData, setMyData] = useState([]); 
  const [userList, setUserList] = useState([]); 

  const [mySellData, setMySellData] = useState({});  
  const [orderData, setOrderData] = useState({});  
  const [happyData, setHappyData] = useState({});  
  const [productData, setProductData] = useState({});  
  const [userDataList, setUserDataList] = useState({});  




    const [pageloading, setPageLoading] = useState(false);

 



  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            
            setUserData(res.data.userInfo)

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);


  const getAllProducts=async()=>{
    const res=await axios.get(`${apilink}/api/product/getAllProductsAdmin`)
    // console.log(res.data)
    if(res.data.success)
    {
      setProductList(res.data.result) 
    }
  }

  const getallorders=async()=>{
    const res=await axios.get(`${apilink}/api/order/getallorders`)
    // console.log(res.data)
    if(res.data.success)
    {
      setMyData(res.data.result) 
    }
  }

  const getOrderProductCategoryforAdmin=async()=>{
    const res=await axios.get(`${apilink}/api/order/getOrderProductCategoryforAdmin`)
    // console.log(res.data)
    if(res.data.success)
    {
      setMySellData({
        type:"totalQuantity",
        data:res.data.result
      })
    }
  }


  const getallusers=async()=>{
    const res=await axios.get(`${apilink}/api/user/getallusers`)
    // console.log(res.data)
    if(res.data.success)
{
  setUserList(res.data.result)
}
  }

  const lastfivedaysOrders=async()=>{
    const res=await axios.get(`${apilink}/api/order/lastfivedaysOrders`)
    // console.log(res.data)
    if(res.data.success)
    {
      setOrderData({
        type:"totalOrders",
        data:res.data.result.reverse()
      })
    }
  }

  const lastfivedaysHappyClientOrders=async()=>{
    
    const res=await axios.get(`${apilink}/api/order/lastfivedaysHappyClientOrders`)
    console.log(res.data)
    if(res.data.success)
    {
      setHappyData({
        type:"totalOrders",
        data:res.data.result.reverse()
      })
    }
  }

  const lastfivedaysAddedProduct=async()=>{
    const res=await axios.get(`${apilink}/api/product/lastfivedaysAddedProduct`)
    // console.log(res.data)
    if(res.data.success)
    {
      setProductData({
        type:"products",
        data:res.data.result.reverse()
      })
    }
  }

  const lastfivedaysAddedUser=async()=>{
    const res=await axios.get(`${apilink}/api/user/lastfivedaysAddedUser`)

    if(res.data.success)
    {
      setUserDataList({
        type:"users",
        data:res.data.result.reverse()
      })
    }
  }

  useEffect(()=>{
    getAllProducts()
    getallorders()
    getOrderProductCategoryforAdmin()
    getallusers()
    lastfivedaysAddedProduct()
    lastfivedaysOrders()
    lastfivedaysHappyClientOrders()
    lastfivedaysAddedUser()
  },[])

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 mb-3">
                <div className="red_card">
                  <div className="media">
                    <h2>Hello, {userData?.name}</h2>
                    <p>Welcome back, your dashboard is ready!</p>
                    <NavLink to="/products" class="btn btn-outline-dark">
                      Get Started <i className="fa fa-long-arrow-right"></i>
                    </NavLink>
                  </div>
                  <div className="cartoon_img">
                    <img src={`${path}/image/welcome.svg`} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 dash">
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2 dflex">
                  <div>
                  <h4>Total Users</h4>
                  <h1>{userList?.length}</h1>
                  </div>
                 {
                  userDataList?.data?.length > 0 && <Linechart data={userDataList}  />
                 }
                  
                  
                </div>
              </div>
              
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2 dflex">
                  <div>
                  <h4>Total Products</h4>
                  <h1>{productList?.length}</h1>
                  </div>
                  {
                    productData?.data?.length > 0 && <Linechart data={productData}  />
                  }
                  
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2 dflex">
                  <div>
                  <h4>Order Requests</h4>
                  <h1>{myData?.length}</h1>
                  </div>
                  {
                    orderData?.data?.length > 0 && <Linechart data={orderData}  />
                  }
                  
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-3">
                <div className="card p-2 dflex">
                  <div>
                  <h4>Happy Clients</h4>
                  <h1>{myData.filter((v)=>v.orderStatus=="Delivered")?.length}</h1>
                  </div>
                  {
                    happyData?.data?.length > 0 && <Linechart data={happyData}  />
                  }
                  
                  
                </div>
              </div>
            </div>
            <div className="row mt-3 ">
              <div className="col-lg-8 col-md-12 mb-3">
                {
                  productList.length > 0 ?(
                    <>
                    <MyListCompo getAllProducts={getAllProducts} productList={productList} alert={alert} token={token} />
                    </>
                  ):(
                    <>
                    <div className="card p-3">
                      <h4>No Item Found</h4>
                    </div>
                    
                    </>
                  )
                }
                
              </div>
              <div className="col-lg-4 col-md-12 mb-3">
                <div className="card p-3">
                <p className='text-center mb-3'>Category & Product Sell Graph</p >
                <Piechart  data={mySellData}  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <Request />
            </div>
          </div>
        </div>
      </div>
      

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default Home;
