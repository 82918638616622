import React, { useState,useEffect } from 'react'
import { useAlert } from 'react-alert'


const AllTag = ({ plac,skills,tags, setTags }) => {
const alert=	useAlert()
	const [search, setSearch] = useState("")
	const [alltech, setAlltech] = useState(skills)
	const removeTags = indexToRemove => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};
	const addTags = event => {
		if (event.target.value !== "") {
			setTags([...tags, event.target.value]);
			// props.selectedTags([...tags, event.target.value]);
			
			setSearch("")
		}
	};

	const addClickTags = (val) => {
		if(tags.indexOf(val)==-1)
		{
			setTags([...tags, val]);
		setSearch("")
		}else{
			alert.info("Already added")
		setSearch("")
		}
	};



	return (
		<>
			<div className="tagbox">
				<div className="tagbox">
					
					<div className="tags-input">
						<ul id="tags">
							{tags.map((tag, index) => (
								<li key={index} className="tag">
									<span className='tag-title'>{tag}</span>
									<span className='tag-close-icon'
										onClick={() => removeTags(index)}
									>
										x
									</span>
								</li>
							))}
						</ul>
						<input
							type="text"
							value={search}
							placeholder={plac}
							onChange={(e) => setSearch(e.target.value)}
							// onKeyUp={event => event.key === "Enter" ? addTags(event) : null}

						/>
					</div>

				</div>
				{
					search && <div className="searchinput">
						<ul>

							{
								alltech?.filter((va) => {
                                    if (search === "") {
                                        return va
                                    }
                                    else if (va.toLowerCase().includes(search.toLowerCase()) ) {
                                        return va
                                    }
                                }).map((val, ind) => {
									return (
										<>
											<li onClick={() => addClickTags(val)}>{val}</li>

										</>
									)
								})
							}


						</ul>

					</div>
				}
			</div>

		</>
	)
}

export default AllTag

