import React, { useEffect } from "react";
import SideBar from "../../component/sidebar/SideBar";
import { path } from "../../data/fdata";
import "./Dashboard.css";
import { useState } from "react";
import SellerDetails from "../../component/sellercompo/SellerDetails";

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { useAlert } from "react-alert";


const ViewSellerByID = () => {

  const his = useHistory();
  
  const alert = useAlert();
  
  const [pageloading, setPageLoading] = useState(false);
  const [sellerProductList, setSellerProductList] = useState([]);
  const [sellerData, setSellerData] = useState({});


  const token = Cookies.get('_indiayour_admin_access_token');
const {sellerid}=useParams()

useEffect(async() => {
  if(token) {
    setPageLoading(true)
      const res=await axios.get(`${apilink}/api/admin/authVerify`,{
          headers: {
            Authorization: token,
          },
        })
        if(!res.data.success)
        {
          Cookies.remove('_indiayour_admin_access_token');
         localStorage.removeItem('_indiayour_admin_access_login');
        console.clear();
        window.location.href = '/';
        }else{
          
         

        }
        setTimeout(() => {
          setPageLoading(false)
        }, 2000);
       
  }else{
    his.push("/")
  }
}, []);


const getsellerById=async()=>{
  
  const res=await axios.get(`${apilink}/api/seller/getsellerById/${sellerid}`)
  // console.log(res.data)
  if(res.data.success){
    setSellerData(res.data.result)
  }
}

const getProductsBySellerIdAdmin=async()=>{
  const res=await axios.get(`${apilink}/api/product/getProductsBySellerIdAdmin/${sellerid}`,{
    headers: {
      Authorization: token,
    },
  })
  // console.log(res.data)
  if(res.data.success){
    setSellerProductList(res.data.result.sort((a,b)=>b.orderCount-a.orderCount))
  }
}

  // 
  useEffect(()=>{
    if(sellerid)
    {
      getsellerById()
      getProductsBySellerIdAdmin()
    }
    
  },[sellerid])

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-12">
                  <div className="card p-3">
                    <h3 className="text_Deco mb-3">Seller Account Details</h3>
                    <h5>Name : <span className="text-custom">{sellerData?.comp_name}</span></h5>
                    <h5>Email: <span className="text-custom">{sellerData?.email}</span></h5>
                    <h5>Phone: <a className="text-custom" href={`tel:${sellerData?.phone}`}>{sellerData?.phone}</a></h5>
                    <div className="text-right mt-2">
                      <button className="btn btn-primary" data-toggle="modal"
                          data-target={`#myyModal${sellerData._id}`}>View</button>
                          
                    </div>
                    <div class="modal fade" id={`myyModal${sellerData._id}`}>
                          <div class="modal-dialog modal-lg modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Seller Business Details</h4>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>

                              <div class="modal-body">
                              <div className="row mt-4 ">
                <div className="col-12">
                 
                    <h3 className="text_Deco mb-3">Business Details</h3>
                    {sellerData?.businessDetails?.address ? (
                      <>
                        <h5>Business Address: {sellerData?.businessDetails?.address}, {sellerData?.businessDetails?.city}, {sellerData?.businessDetails?.pincode}, {sellerData?.businessDetails?.state}</h5>
                        <h5> Business Type: {sellerData?.businessDetails?.businessType}</h5>
                        <h5> Industry or Category:{sellerData?.businessDetails?.category}</h5>
                        <h5> Seller Description:{sellerData?.businessDetails?.sellerDet}</h5>
                        <h5> Team Size:{sellerData?.businessDetails?.teamsize}</h5>
                        <h5> GSTIN : {sellerData?.businessDetails?.gstinNo}  </h5>
                        <img src={sellerData?.businessDetails?.gstinFile} alt="" className="doc_fileimg" />
                        <h5> PAN:{sellerData?.businessDetails?.panNo} </h5>
                        <img src={sellerData?.businessDetails?.panFile} alt="" className="doc_fileimg" />

                        
                      </>
                    ) : (
                      <>
                        <div className="text-left">
                          <button  className="btn btn-primary">Not</button>
                        </div>
                      </>
                    )}
                  
                </div>
              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
             
                  {
                    sellerProductList.length > 0 ?(
                      <>
                           <h3 className="text_Deco mb-3 ml-2">All Products</h3>
                      <SellerDetails token={token} alert={alert} sellerProductList={sellerProductList} />
                      </>
                    )  :(

                      <>
                          <div className="text-center p-3">
                            <p>No Product Found</p>
                          </div>
                      </>
                    ) 
                  }
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default ViewSellerByID;
