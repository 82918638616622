import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { apilink, mainpath, removeSpecialCharacters } from "../../data/fdata";
import Cookies from "js-cookie";
import axios from "axios";

const MyListCompo = ({ getAllProducts, productList, alert, token }) => {
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState("");
  const [activeId, setActiveId] = useState("");
  const his = useHistory();

  const [myData, setMyData] = useState(productList);

  const [filter, setFilter] = useState("all");

  useEffect(() => {
    if (filter == "all") {
      setMyData(productList);
    } else {
      setMyData(productList.filter((v) => v.isProApproved == filter));
    }
  }, [filter]);

  const onDelete = async (id) => {
    const res = await axios.get(
      `${apilink}/api/product/deleteProductById/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log(res.data)
    if (res.data.success) {
      alert.success(res.data.msg);
      setMyData(productList.filter((product) => product._id !== id));
      getAllProducts()
    } else {
      if (res.data.msg == "Invalid Authentication.") {
        Cookies.remove("_indiayour_admin_access_token");
        localStorage.removeItem("_indiayour_admin_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        alert.error(res.data.msg);
      }
    }
  };

  const onupdateProductStatus = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios.post(
      `${apilink}/api/product/updateStatus`,
      {
        status,
        oid: activeId,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    // console.log(res.data)

    if (res.data.success) {
      alert.success(res.data.msg);
      // console.log(activeId);
      const updatedItems = myData.map((item) =>
        item._id == activeId ? {...item, ['isProApproved']: status } : item
      );
      // console.log(updatedItems);
      setStatus("")
      setMyData(updatedItems);
    } else {
      if (res.data.msg == "Invalid Authentication.") {
        Cookies.remove("_indiayour_admin_access_token");
        localStorage.removeItem("_indiayour_admin_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        alert.error(res.data.msg);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="card p-3">
        <div className="dd_flex">
          <h5 className="text_Deco">Recent Products</h5>
          <div className="d_flex">
            <div class="form-group">
              <select
                class="form-control filterform"
                id="sel1"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
              </select>
            </div>
            <NavLink to="/products" className="textm">
              View All
            </NavLink>
          </div>
        </div>
        <div class="table-responsive listmy">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th className="w_d">Date</th>
                <th className="tr_w">Product Name</th>
                <th className="w_30">SellerName</th>
                <th className="w_20">Price</th>
                <th className="w_10 ">Qty</th>
                <th className="w_20">Status</th>
              </tr>
            </thead>
            <tbody>
             {
              myData.length > 0 ?(
                <>
                 {myData.slice(0, 10).map((val, ind) => {
                return (
                  <>
                    <tr className="">
                      <td className="w_d">{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>
                      <td className="tr_w">
                        <div className="small_det">
                          <img src={val.pro_images[0]} alt="" />

                          <div>
                            <p>{val.pro_title}</p>
                            <span
                              class="badge badge-primary m-1 cur"
                              onClick={() =>
                                his.push(`/product/${val._id}/edit`)
                              }
                            >
                              View
                            </span>
                            <span
                              class="badge badge-warning m-1 cur"
                              data-toggle="modal"
                              data-target={`#myyyModal/${val._id}`}
                              onClick={() => setActiveId(val._id)}
                            >
                              Update
                            </span>
                            <a href={`${mainpath}/product/${removeSpecialCharacters(val.pro_title)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/DEMO`} target="_blank" class="badge badge-info m-1 ">Link</a>

                            <div class="modal fade" id={`myyyModal/${val._id}`}>
                              <div class="modal-dialog modal modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="modal-title">
                                      Update Product Status
                                    </h4>
                                    <button
                                      type="button"
                                      class="close"
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                  </div>

                                  <div class="modal-body">
                                    <div className="row">
                                      <div className="col-lg-8 col-12 mx-auto">
                                        <h5 className="text-center">
                                          Are you Sure About Delete ?{" "}
                                          <span
                                            class="badge badge-warning m-1 cur"
                                            onClick={() => onDelete(val._id)}
                                          >
                                            Delete
                                          </span>{" "}
                                        </h5>
                                        <br />
                                        <form onSubmit={onupdateProductStatus}>
                                          <div class="form-group">
                                            <select
                                              class="form-control "
                                              id="sel1"
                                              value={status}
                                              onChange={(e) =>
                                                setStatus(e.target.value)
                                              }
                                              required
                                            >
                                              <option selected hidden value="">
                                                --Choose--
                                              </option>

                                              <option value="approved">
                                                Approved
                                              </option>
                                              <option value="rejected">
                                                Rejected
                                              </option>
                                              <option value="pending">
                                                Pending
                                              </option>
                                            </select>
                                          </div>
                                          <div className="text-center">
                                            <button
                                              type="submit"
                                              className={
                                                loading
                                                  ? "dis btn btn-primary"
                                                  : "btn btn-primary"
                                              }
                                              disabled={loading}
                                            >
                                              Update Status
                                            </button>
                                          </div>
                                          {loading && (
                                            <div className="text-center p-2">
                                              <CircularProgress
                                                color="error"
                                                size={35}
                                              />
                                            </div>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="w_30">
                      
                      <NavLink to={`/seller/${val.pro_creator?._id}/products`} className="text-custom">
                           {val?.pro_creator?.comp_name}
                          </NavLink>
                      </td>
                      <td className="w_20">₹{val.pro_price}</td>
                      <td className="w_10 pl-3">{val.pro_qty}</td>
                      <td className="w_20">
                        {val.isProApproved == "pending" && (
                          <span class="badge badge-warning m-1">Pending</span>
                        )}
                        {val.isProApproved == "approved" && (
                          <span class="badge badge-success m-1">Approved</span>
                        )}
                        {val.isProApproved == "rejected" && (
                          <span class="badge badge-danger m-1">Rejected</span>
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
                </>
              ):(
                <>
                <div className="p-3">
                  <h5>No Item Found</h5>
                </div>
                
                </>
              )
             }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyListCompo;
