import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useHistory } from "react-router-dom";
import { apilink, mainpath, path, removeSpecialCharacters } from "../../data/fdata";
import axios from "axios";
import Cookies from "js-cookie";
import ReactPaginate from 'react-paginate';

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import SideBar from "../../component/sidebar/SideBar";

const ProductSellDetails = () => {
  const token = Cookies.get("_indiayour_admin_access_token");

  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [filter, setFilter] = useState("TL");




  

  const [pageNo, setPageNo] = useState(0);
  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = productList.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(productList.length / perpage);

  const likedChange = ({ selected }) => {
    setPageNo(selected);
  };


  useEffect(async () => {
    if (token) {
      setPageLoading(true);
      const res = await axios.get(`${apilink}/api/admin/authVerify`, {
        headers: {
          Authorization: token,
        },
      });
      if (!res.data.success) {
        Cookies.remove("_indiayour_admin_access_token");
        localStorage.removeItem("_indiayour_admin_access_login");
        console.clear();
        window.location.href = "/";
      } else {
        
      }
      setTimeout(() => {
        setPageLoading(false);
      }, 2000);
    } else {
      his.push("/");
    }
  }, []);

  const getOrderProductCountforadmin = async () => {
    const res = await axios.get(
      `${apilink}/api/order/getOrderProductCountforadmin`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // console.log(res.data);
    if (res.data.success) {
      setProductList(res.data.result.sort((a,b)=>b.totalQuantity - a.totalQuantity));
    }
  };

  useEffect(() => {
    getOrderProductCountforadmin();
  }, []);

  const handelItem=(e)=>{
    setFilter(e.target.value)
    if(e.target.value=="TL")
    {
        setProductList(productList.sort((a,b)=>b.totalQuantity - a.totalQuantity));
    }else{
        setProductList(productList.sort((a,b)=>a.totalQuantity - b.totalQuantity));
    }
  }
  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <h3 className="text-custom">Product Sell Details</h3>
              <div className="dd_flex">
          <h5></h5>
          <div>
            <div class="form-group">
              <select class="form-control filterform"  value={filter} onChange={handelItem} >
                
               
                <option value="TL">Qty:T-L</option>
                <option value="LT">Qty:L-T</option>
               
              </select>
            </div>
          </div>
        </div>

             {
              dataall.length > 0 ?(
                <>
                 <div className="productflex mt-3">
               {
                dataall?.map((val,ind)=>{
                    return(
                        <>
                         <div className="product_item">
                  <div className="diplay_flex">
                    {
                        val.productImage?.length > 0 && <img src={val.productImage[0]} alt="" className="cur" onClick={() => his.push(`/product/${val._id}/edit`)} />
                    }
                   

                    <div className="ml-2">
                      <p
                        className="m-0 sm_p fn_18 cur mb-2"
                        onClick={() => his.push(`/product/${val._id}/edit`)}
                      >
                        {val?.productName}
                      </p>

                      <p className="fn_14 m-0">
                        <b>Total Request:</b> &nbsp; {val?.totalOccurrences}
                      </p>
                      <p className="fn_14 m-0">
                        <b>Total Qty:</b> &nbsp; {val?.totalQuantity}
                      </p>
                      <p><span className="badge badge-primary cur" data-toggle="modal"
                            data-target={`#myyModal${val._id}`}>view</span>  <a class="badge badge-dark m-1 text-light" href={`${mainpath}/product/${removeSpecialCharacters(val.productName)?.trim().toLowerCase().replace(/\s/g, "-")}/${val._id}/DEMO`} target="_blank">Link</a></p>

                             <div class="modal fade" id={`myyModal${val._id}`}>
                            <div class="modal-dialog  modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h4 class="modal-title">Seller Details</h4>
                                  <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                </div>

                                <div class="modal-body">
                                <h5>Company Name : <span className="text-custom">{val?.productSeller?.sellerName}</span></h5>
                                    <h5>Phone : <a  className="text-custom" href={`tel:${val?.productSeller?.sellerPhone}`} >{val?.productSeller?.sellerPhone}</a></h5>
                                    <h5>Email : <span className="text-custom">{val?.productSeller?.sellerEmail}</span></h5>

                                 <div className="text-right">
                                  <NavLink target="_blank" to={`/seller/${val.productSeller._id}/products`}><i  className="cur fa fa-eye text-custom"></i></NavLink>
                                 
                                 </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
                        </>
                    )
                })
               }
                
              </div>
                
                </>
              ):(
                <>
                <div className="text-center">
                  <div className="p-3">
                    <p>No Item Found</p>
                  </div>
                </div>
                </>
              )
             }


{
            dataall.length > 0 && <div className="row mt-3">
            <ReactPaginate
              previousLabel={'Prev'}
              nextLabel={'Next'}
              pageCount={boxno}
              onPageChange={likedChange}
              containerClassName={'pagination'}
              // previousLinkClassName={"prevbutton"}
              // nextLinkClassName={"nextbutton"}
              // disabledClassName={"pagedisable"}
              activeClassName={'activebutton'}
            />
          </div>
          }
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductSellDetails;
