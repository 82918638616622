import React, { useEffect } from 'react';
import './Dashboard.css';
import { NavLink, useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useState } from 'react';
import { useAlert } from 'react-alert';

const AccountDet = () => {
  const [pageloading, setPageLoading] = useState(false);
    const alert = useAlert();

    const token = Cookies.get('_indiayour_admin_access_token');
    const his = useHistory();
    const [userData, setUserData] = useState([]);

    useEffect(async() => {
      if(token) {
        setPageLoading(true)
          const res=await axios.get(`${apilink}/api/admin/authVerify`,{
              headers: {
                Authorization: token,
              },
            })
            if(!res.data.success)
            {
              Cookies.remove('_indiayour_admin_access_token');
             localStorage.removeItem('_indiayour_admin_access_login');
            console.clear();
            window.location.href = '/';
            }else{
              console.log(res.data)
              setUserData(res.data.userInfo)
  
            }
            setTimeout(() => {
              setPageLoading(false)
            }, 2000);
           
      }else{
        his.push("/")
      }
    }, []);

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-12">
                  <div className="card p-3">
                    <h3 className="text_Deco mb-3">Account Details</h3>
                    <h5>Name : {userData?.name}</h5>
                    <h5>Email: {userData?.email}</h5>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDet;
