import React, { useEffect } from 'react';
import './Dashboard.css';
import { useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useAlert } from 'react-alert';
import CKeditorCom from './CKeditorCom';
import { CategoryList, placelist, teamList } from '../../data/stateList';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AllTag from '../../component/tag/AllTag';
import JoditEditorComp from '../../component/tag/JoditEditorComp';
const EditItem = () => {
  
  const token = Cookies.get('_indiayour_admin_access_token');


  // console.log(categoryList)


 const [categoryList,setCategoryList]= useState([])

  const alert = useAlert();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [imageList, setImageList] = useState([]);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [categoryid, setCategoryid] = useState('');
  const [subCategoryid, setSubCategoryid] = useState('');

  const [place, setPlace] = useState([]);
  const [discount, setDiscount] = useState(''); 

  // pro_gst_percentage,  pro_sac_code  use state 
  const [pro_gst_percentage, setPro_gst_percentage] = useState();
  const [pro_sac_code, setPro_sac_code] =useState("")

  const [slug, setSlug] =useState("")
  const [meta_title, setMeta_title] =useState("")
  const [meta_description, setMeta_description] =useState("")
  const [img_alt, setImg_alt] =useState("")
  const [pdfLink, setPdfLink] = useState('');




  

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [addAll,setAddAll] = useState(true);
  
  const his = useHistory();

  const {proid}=useParams()


  const [pageloading, setPageLoading] = useState(false);


  const onAdd = async (e) => {
    e.preventDefault();
    setLoading(true)
   
    const data={
      pro_title:title,
      pro_qty:quantity,
      pro_description:description,
      pro_discount:discount,
      pro_price:price,
      pro_category:category,
      pro_category_id:categoryid,
      pro_subcategory:subCategory,
      pro_subcategory_id:subCategoryid,
      pro_place:place,
      pro_images:imageList,
      pro_gst_percentage,
      pro_sac_code,
      slug,
      meta_title,
      meta_description,
      img_alt,
      pro_pdf_link:pdfLink

    }
    
    const res=await axios.post(`${apilink}/api/product/updateProduct/${proid}`,data,{
      headers: {
        
        Authorization: token,
      },
    })
// console.log(res.data)
if(res.data.success){
  alert.success(res.data.msg)
  setTimeout(() => {
    his.push("/products")
  }, 2000);

}else{

  alert.error(res.data.msg)

}
       
   
    setLoading(false)
  };

  const getproductById=async()=>{
    const res=await axios.get(`${apilink}/api/product/getproductById/${proid}`)
    const ress=await axios.get(`${apilink}/api/category/getallCategory`)
    // console.log(res.data.result)
    // console.log(ress.data.result)
    if(res.data.success){
      setTitle(res.data.result.pro_title)
      setQuantity(res.data.result.pro_qty)
      setPrice(res.data.result.pro_price)
      setCategory(res.data.result.pro_category)
      setCategoryList(ress.data.result)
      
      let catlist = ress.data.result.filter(
        (v) => v.cat_name == res.data.result.pro_category
      );

      setSubCategoryList(catlist[0]?.sub_category);
      setSubCategory(res.data.result.pro_subcategory)
      setImageList(res.data.result.pro_images)
      setDescription(res.data.result.pro_description)
      setPlace(res.data.result.pro_place)
      setDiscount(res.data.result.pro_discount)
      setPro_gst_percentage(res.data.result.pro_gst_percentage)
      setPro_sac_code(res.data.result.pro_sac_code)
      setSlug(res.data.result.pro_metadetails.slug)
      setMeta_title(res.data.result.pro_metadetails.meta_title)
      setMeta_description(res.data.result.pro_metadetails.meta_description)
      setImg_alt(res.data.result.pro_metadetails.img_alt)
      setPdfLink(res.data.result.pro_pdf_link)



    }else{
      his.push("/something-wrong")
      alert.error(res.data.msg)
    }
  }

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }else{
            
           

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);
 
  useEffect(()=>{
    if(proid){

      getproductById()

    }

  },[proid])

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <h3 className="text_Deco">Edit Item</h3>
              <div className="row mt-5 p-2">
              <div className="col-12">
                 
              <form onSubmit={onAdd}>
              <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label for="address">Enter Slug:</label>
                        <input
                          type="text"
                          placeholder="Enter slug"
                          className="form-control form_h"
                          name="slug"
                          value={slug}
                          onChange={(e) => setSlug(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Enter Meta Title</label>
                        <input
                          type="text"
                          placeholder="Enter meta_title"
                          className="form-control form_h"
                          name="meta_title"
                          value={meta_title}
                          onChange={(e) => setMeta_title(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div class="form-row">
                    <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Enter Img Alt</label>
                        <input
                          type="text"
                          placeholder="Enter img alt"
                          className="form-control form_h"
                          name="img_alt"
                          value={img_alt}
                          onChange={(e) => setImg_alt(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label for="address">Enter meta description:</label>
                        <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter meta description"
                            value={meta_description}
                            onChange={(e) => setMeta_description(e.target.value)}
                            required
                          ></textarea>
                      </div>
                     
                    </div>



                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label for="address">Enter title:</label>
                        <input
                          type="text"
                          placeholder="Enter title"
                          className="form-control form_h"
                          name="address"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Enter Quantity</label>
                        <input
                          type="number"
                          placeholder="Enter Quantity"
                          className="form-control form_h"
                          name="quantity"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter Price</label>
                        <input
                          type="number"
                          placeholder="Enter Price"
                          className="form-control form_h"
                          name="price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter Discount</label>
                        <input
                          type="number"
                          placeholder="Enter Discount"
                          className="form-control form_h"
                          name="discount"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                          required
                        />
                      </div>
                     
                    </div>
                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter SAC Code</label>
                        <input
                          type="text"
                          placeholder="Enter SAC Code"
                          className="form-control form_h"
                          name="sacCode"
                          value={pro_sac_code}
                          onChange={(e) => setPro_sac_code(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter GST Percentage</label>
                        <select
                          class="form-control form_h"
                          value={pro_gst_percentage}
                          onChange={(e) => {
                            setPro_gst_percentage(e.target.value);
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          <option value="5">5%</option>
                          <option value="12">12%</option>
                          <option value="18">18%</option>
                          <option value="28">28%</option>

                          
                        </select>
                      </div>
                     
                    </div>

                    <div class="form-row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter Category</label>
                        <select
                          class="form-control form_h"
                          value={category}
                          onChange={(e) => {
                            let catlist = categoryList.filter(
                              (v) => v.cat_name == e.target.value
                            );

                            setSubCategoryList(catlist[0].sub_category );
                            setCategory(e.target.value);
                            setCategoryid(catlist[0]._id)
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category-
                          </option>
                          {categoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.cat_name}>
                                  {val.cat_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Enter SubCategory</label>
                        <select
                          class="form-control form_h"
                          value={subCategory}
                          onChange={(e) => {
                            let catlist = subCategoryList.filter(
                              (v) => v.subcat_name == e.target.value
                            );
                            setSubCategory(e.target.value);
                            setSubCategoryid(catlist[0]._id)
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose SubCategory-
                          </option>
                          {subCategoryList?.map((val, ind) => {
                            return (
                              <>
                                <option key={ind} value={val.subcat_name}>
                                  {val.subcat_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                    <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Selected Images  <small className='text-custom cur ' data-toggle="modal"
                            data-target="#myModal">View</small> </label>
                        <input
                          type="file"
                          className="form-control form_h"
                          name="imgfile"
                          multiple
                          accept=".png, .jpeg , .jpg"
                          disabled
                          
                        />
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                      <label>Enter Place &nbsp;
                        {
                          addAll?(
                            <>
                             <small className='badge badge-danger cur' onClick={()=>{
                          setAddAll(false)
                          setPlace(placelist)
                        }}>Add All</small>
                            
                            </>
                          ):(
                            <>
                             <small className='badge badge-danger cur' onClick={()=>{
                          setAddAll(true)
                          setPlace([])
                        }}>Remove All</small>
                            
                            </>
                          )
                        } </label>
                        
                    <AllTag
                      skills={placelist}
                      tags={place}
                      setTags={setPlace}
                      plac="Enter places"
                     
                    />
                        
                      </div>
                    </div>
                    <div class="form-row">
                    <div className="form-group col-lg-6 col-md-6">
                        <label htmlFor="">Upload Product Details Pdf &nbsp; {pdfLink?.length &&   <a target='_blank'  href={`https://docs.google.com/viewer?url=${pdfLink}&embedded=true&a=bi`}  className='text-custom cur'>view</a> }</label>
                        <input
                          type="file"
                          className="form-control form_h"
                          name="imgfile"
                         
                          accept="application/pdf"
                          // required
                          disabled
                        />
                      </div>
                      
                    </div>
                    <div className="form-group">
                      <label>Enter Description:</label>
                      {/* <CKeditorCom
                        text={description}
                        setText={setDescription}
                      /> */}
                      <JoditEditorComp
                      text={description}
                      setText={setDescription}
                      />
                    </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? 'dis btn btn-primary pl-5 pr-5'
                            : 'btn btn-primary pl-5 pr-5'
                        }
                        disabled={loading}
                      >
                        Update Details
                      </button>
                    </div>
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress color="error" size={35} />
                      </div>
                    )}
                  </form>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Images</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div className="img_flex">
                {
                  imageList?.map((val,ind)=>{
                    return (
                      <div className="img_flex_item">
                  <img src={val} alt="" />
                </div>
                    )
                  })
                }
              </div>
              {/* {
              showValue ?  <img src={showValue} alt="" className='doc_img' /> : "loading..."
            } */}

              {/* <img src={showValue} alt="" className="doc_img" /> */}
            </div>
          </div>
        </div>
      </div>

      {pageloading && (
        <>
          <div className="pageloading">
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditItem;
