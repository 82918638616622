import React, { useEffect } from 'react';
import './Dashboard.css';
import { useHistory } from 'react-router-dom';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import Request from '../../component/homecompo/Request';
import BigRequest from '../../component/homecompo/BigRequest';
import { useState } from 'react';

const OrderRequest = () => {
  const his = useHistory();
  const token = Cookies.get('_indiayour_admin_access_token');
  const [pageloading, setPageLoading] = useState(false);

 



  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          if(!res.data.success)
          {
            Cookies.remove('_indiayour_admin_access_token');
           localStorage.removeItem('_indiayour_admin_access_login');
          console.clear();
          window.location.href = '/';
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 2000);
         
    }else{
      his.push("/")
    }
  }, []);

  return (
    <>
      <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              {/* <h3 className="text_Deco">Order Requests</h3> */}
              <div className="row mt-5">
                <BigRequest  setPageLoading={setPageLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>


      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
    </>
  );
};

export default OrderRequest;
