import './Need.css'
import React, { useEffect, useState } from 'react';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useAlert } from 'react-alert';
import JoditEditorComp from '../../component/tag/JoditEditorComp';

const EditorCompo = ({ctype , ctext , setPageLoading}) => {
    
    const alert = useAlert();
  const [content, setContent] = useState('');
  const [contentType, setContentType] = useState(ctype);
  const [contentId, setContentId] = useState('');



    const token = Cookies.get('_indiayour_admin_access_token');
   

      const submitContent=async(e)=>{
        e.preventDefault()
        setPageLoading(true)
        
        if(contentId)
        {
          const data={
            content,
            contentType,
            contentId
  
          }
          
          const res=await axios.post(`${apilink}/api/pagecontent/updatePageContent`,data,{
            headers: {
              
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(res.data.success)
          {
            alert.success(res.data.msg)
          }else{
            alert.error(res.data.msg)
          }
        }else{
          const data={
            content,
            contentType
  
          }
          const res=await axios.post(`${apilink}/api/pagecontent/postPageContent`,data,{
            headers: {
              
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(res.data.success)
          {
            alert.success(res.data.msg)
          }else{
            alert.error(res.data.msg)
          }
        }
        

        setPageLoading(false)
      }
      const getPageContent=async()=>{
        const res=await axios.get(`${apilink}/api/pagecontent/getPageContent/${ctype}`)
        if(res.data.success )
        {
          setContent(res.data.result?.content)
          setContentId(res.data.result?._id)
        }
      }
      useEffect(()=>{
        getPageContent()
      },[])

  return (
    <>
    
      
    <div className="row">
              <div className="col-lg-12 col-md-12 col-12 mb-3">
               <div className="new_dd_flex mb-4">
               <h3 className='text_Deco mb-3'>{ctext}</h3>
               {/* <button className='btn btn-primary' >Preview</button> */}
               </div>
               <form onSubmit={submitContent}>
               <div className="form-group">
                     
                      {/* <CKeditorCom
                        text={content}
                        setText={setContent}
                      /> */}
                      <JoditEditorComp 
                      text={content}
                      setText={setContent}
                      />
                    </div>

                    <div className="text-right">
                    

                      <button
                        type="submit"
                        className="btn btn-primary pl-5 pr-5 m-1"
                        
                      >
                        Submit
                      </button>
                    </div>
                    
               </form>
              </div>
            </div>
      
    
    </>
  )
}

export default EditorCompo