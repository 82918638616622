import React, { useState, useEffect } from 'react';
import { useHistory , useParams } from 'react-router';
import './Auth.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import { apilink, path } from '../../data/fdata';
import { useAlert } from 'react-alert';

const ResetPassword = () => {
    const alert = useAlert();
  const [npassword, setNPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const { accesstoken} = useParams();
  const [ntype, setNtype] = useState("password");
  const [ctype, setCtype] = useState("password");
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const his = useHistory();

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);
if(npassword.length < 6) {
    setTimeout(() => {
        setLoading(false);
        setMsg("Password Length must be at least 6 characters");
      setStatus(true);
      }, 2000);

}else if(npassword !=cpassword)
{
    setTimeout(() => {
        setLoading(false);
        setMsg("Password Not Matched");
      setStatus(true);
      }, 2000);
}else{

    const res=await axios.post(`${apilink}/api/admin/resetpassword`,{
        password:npassword,
        accesstoken
      })
      // console.log(res.data)
  
      if(res.data.success)
      {
          setTimeout(() => {
              setLoading(false);
              setMsg("Password Updated Successfully");
            setStatus(true);
            }, 2000);
  
            setTimeout(() => {
              his.push("/")
            }, 4000);
       
  
      }else{
        setTimeout(() => {
          setLoading(false);
          setMsg((res.data.msg== "jwt expired" || res.data.msg== "invalid token" ) ? "Invalid Authentication." : res.data.msg);
        setStatus(true);
        }, 2000);
      }

}

    


    
  };

  return (
   <>
   
   <div className="auth">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="card p-3">
                {status ? (
                  <>
                    <div class="alert alert-warning alert-dismissible">
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {msg}
                    </div>
                  </>
                ) : null}
    <img
                    src={`${path}/image/Indiayour.png`}
                    alt=""
                    className="logoimg"
                  />
                <h3 className="text-center pb-3">Reset Password</h3>
                <br />
                <form onSubmit={onSub} className="">
                  <div class="form-group passform">
                    <input
                       type={ntype}
                      placeholder="Enter New Password"
                      class="form-control"
                      name="npassword"
                      value={npassword} 
                      onChange={(e) => setNPassword(e.target.value)}
                      required
                    />
                    <div>
                     {ntype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setNtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setNtype("password")}></i> }
                     </div>
                  </div>
                  <div class="form-group passform">
                    <input
                       type={ctype}
                      placeholder="Confirm Password"
                      class="form-control"
                      name="cpassword"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                    <div>
                     {ctype == 'password' ?  <i className='fa fa-eye-slash cur' onClick={()=>setCtype("text")}></i> :  <i className='fa fa-eye cur' onClick={()=>setCtype("password")}></i> }
                     </div>
                  </div>

                  
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading}
                    >
                      Update Now
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress color="error" size={35} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default ResetPassword