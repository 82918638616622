import React, { useEffect, useState } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import SideBar from '../../component/sidebar/SideBar';
import { useAlert } from 'react-alert';
import './Banner.css'
import { CircularProgress } from '@mui/material';
const Ads = () => {
    const [pageloading, setPageLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [adslink, setAdsLink] = useState("");
    const [adsImg, setAdsImg] = useState([]);
    const [adsList,setAdsList]=useState([]);
    const [adsType, setAdsType] = useState("");


    const alert = useAlert();

    const token = Cookies.get('_indiayour_admin_access_token');
    const his = useHistory();

    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              if(!res.data.success)
              {
                Cookies.remove('_indiayour_admin_access_token');
               localStorage.removeItem('_indiayour_admin_access_login');
              console.clear();
              window.location.href = '/';
              }else{
                
                // setUserData(res.data.userInfo)
    
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 2000);
             
        }else{
          his.push("/")
        }
      }, []);

      const uploadAds=async(e)=>{
        e.preventDefault()
        setLoading(true)

       

        let formData = new FormData();
       
        formData.append("file", adsImg);
      
        const res = await axios.post(`${apilink}/api/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: token,
          },
        });
        // console.log(res.data)
       
        if(res.data.success)
        {
        //   let imgarr=[];
        // res.data.result.forEach(img=>{
        //   imgarr.push(img.Location);
        // })

        
          const data={
              adslink,
              adsImg:res.data.result[0].Location,
              adsType
        
          }
          // console.log(data)
          const ress=await axios.post(`${apilink}/api/adds/postAds`,data,{
            headers: {
              
              Authorization: token,
            },
          })
          if(ress.data.success)
          {
            
            alert.success("Ads Image added successfully")
            setAdsImg([])
            setAdsLink("")
            setAdsType("")

            getAllAds()
          //   setTimeout(() => {
          //     window.location.reload()
          //   }, 2000);
          }else{
            alert.error(ress.data.msg)
      
          }
        
          
      
        }else{
          alert.error(res.data.msg)
        }

        setLoading(false)

      }

       const handelFile = (e) => {
        const { files } = e.target;
       
        // console.log(files[0]); files[0].type === 'application/pdf'
        if (files.length > 0) {
          if (files[0].size > 1000000) {
            alert.error(` File should be less then 1 MB`);
           
          } else if (
            files[0].type === "image/jpeg" ||
            files[0].type === "image/jpg" ||
            files[0].type === "image/png"
          ) {
           
            setAdsImg(files[0]);
           
          } else {
            
            alert.error(`File extension should be only jpg, png , jpeg`);
          }
         
        }
        
      };

      const getAllAds=async()=>{
        const res=await axios.get(`${apilink}/api/adds/getAllAds`)
        // console.log(res.data)
    
        if(res.data.success){
            setAdsList(res.data.result)
    
        }else{
            alert.error(res.data.msg)
        }
      }



      useEffect(()=>{
        getAllAds()
      },[])

      const deleteAds=async(id)=>{

        const res=await axios.get(`${apilink}/api/adds/deleteAds/${id}`,{
            headers: {
        
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success)
        {
            alert.success(res.data.msg)
            let ar=adsList.filter((v)=>v._id != id)
            setAdsList(ar)
        }else{
            alert.error(res.data.msg)
        }
      }

  return (
  <>
  <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 mb-3">
              <div className="new_dd_flex">
              <h5 className="text_Deco">Two Ads</h5>
              <button className='btn btn-primary' onClick={()=>{
                setAdsType("TWO")
              }} data-toggle="modal"
                            data-target='#myModal'> <i className='fa fa-cloud-upload'></i> Upload Ads</button>


              <div class="modal fade" id='myModal'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Upload Ads</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={uploadAds}>

                <div className="form-group ">
                       <label for="address">Ads Link:</label>
                       <input
                         type="text"
                         placeholder="Enter Link"
                         className="form-control form_h"
                         name="adslink"
                         value={adslink}
                         onChange={(e) => setAdsLink(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Upload Ads Image: Size (500 x 120)</label>
                       <input
                         type="file"                         
                         className="form-control form_h"
                         name="image"
                        
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelFile}
                         required
                       />
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>

              </div>
              </div>
              <div className="col-12 mx-auto mt-5">
            
           {
            adsList.filter((v)=>v.adsType=="TWO").length > 0 ? (
              <>
               <div className="banner_flex ">
              {
                adsList.filter((v)=>v.adsType=="TWO").map((val,ind)=>{
                  return(
                    <>

<div className="banner_flex_item " key={ind} >
                                  
                                  <img src={val.adsImg} alt="" />
                         
                         
                          <div className="delete_btn" onClick={()=>deleteAds(val._id)}>
                              <i className='fa fa-trash' ></i>       
                          </div>

                      </div>
                    </>
                  )
                })
              }
               </div>
              </>
            ):(
              <>
              <p className='text-center'>No Item Found</p>
              </>
            )
           }
                        
           
             </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-12 mb-3">
              <div className="new_dd_flex">
              <h5 className="text_Deco">Single Ads</h5>
              <button className='btn btn-primary' onClick={()=>{
                setAdsType("ONE")
              }} data-toggle="modal"
                            data-target='#myModal1'> <i className='fa fa-cloud-upload'></i> Upload Ads</button>


              <div class="modal fade" id='myModal1'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Upload Ads</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={uploadAds}>

                <div className="form-group ">
                       <label for="address">Ads Link:</label>
                       <input
                         type="text"
                         placeholder="Enter Link"
                         className="form-control form_h"
                         name="adslink"
                         value={adslink}
                         onChange={(e) => setAdsLink(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Upload Ads Image: Size (1000 X 500) </label>
                       <input
                         type="file"                         
                         className="form-control form_h"
                         name="image"
                        
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelFile}
                         required
                       />
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>

              </div>
              </div>
              <div className="col-12 mx-auto mt-5">
            
           {
            adsList.filter((v)=>v.adsType=="ONE").length > 0 ? (
              <>
               <div className="ads_flex">
              {
                adsList.filter((v)=>v.adsType=="ONE").map((val,ind)=>{
                  return(
                    <>

<div className="banner_flex_item  s_ad_img" key={ind} >
                                  
                                  <img src={val.adsImg} alt="" />
                         
                         
                          <div className="delete_btn" onClick={()=>deleteAds(val._id)}>
                              <i className='fa fa-trash' ></i>       
                          </div>

                      </div>
                    </>
                  )
                })
              }
               </div>
              </>
            ):(
              <>
              <p className='text-center'>No Item Found</p>
              </>
            )
           }
                        
           
             </div>
            </div>

          </div>
        </div>
      </div>
      

      {
        pageloading && (
          <>
          <div className="pageloading">
          <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

          </>
        )
      }
  </>
  )
}

export default Ads